import React from 'react';
import ReviewsCarousel from "../../reviews/ReviewsCarousel";
import {review_1, review_2, review_3, review_4, review_5} from "../assets"

export const AllReviews = () => {

    const data = [review_1, review_2, review_3, review_4, review_5]

    return (
        <ReviewsCarousel
            maxCount={data?.length}
            reviews={data}
            linkVk={"https://vk.com/topic-217844762_49601223"}
        />
    );
};
