import React from 'react';

const useLocalStorage = (key, DEFAULT) => {
    function getItem() {
        let lsItem = localStorage.getItem(key)
        try {
            lsItem = JSON.parse(lsItem)
        } catch (e) {
            lsItem = DEFAULT
        }

        if(!lsItem)
            lsItem = DEFAULT

        return lsItem
    }

    function setItem(newValue) {
        let lsItem = newValue
        try {
            lsItem = JSON.stringify(lsItem)
        } catch (e) {

        }

        localStorage.setItem(key, lsItem)
    }

    function clearItem () {
        localStorage.removeItem(key)
    }
    return [getItem, setItem, clearItem]
};

export default useLocalStorage;