import {useEffect} from 'react';

const useSetMeta = (meta: any) => {
    function onUpd() {
        let title = document.querySelector('title')
        if (title) title.innerText =  meta.title;

        let desc = document.querySelector('meta[name="description"]')
        if (desc) desc.setAttribute("content", meta.description)
    }

    useEffect(()=>{
        onUpd()
    }, [])
};

export default useSetMeta;