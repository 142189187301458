import React, {useEffect, useState} from 'react';
import CardSubscribe from "./CardSubscribe";
import {getAllSubscribes} from "../../../service/api/subscribeApi";

const ListSubscribes = (props: any) => {
    const [data, setData] = useState<any>(undefined)
    const {subscribe, setSubscribe} = props

    useEffect(() => {
        getAllSubscribes().then(res => setData(res))
    }, [])

    useEffect(() => {
        if (data) setSubscribe(data[data?.length - 1])
        else setSubscribe(null)
    }, [data])

    return (
        <section className="p-2 md:max-w-[1000px] m-auto">
            <h4 className="text-2xl text-center -mt-6 md:text-4xl ">
                Проходите лучшие курсы по подписке
            </h4>
            <section className="p-2 md:max-w-[600px] mt-8 m-auto md:text-lg">
                <div style={{textAlign: "center"}}>
                    <p>
                        Подписка открывает вам доступ почти ко всем нашим материалам! Оформите подписку прямо сейчас
                        по самому выгодному тарифу!
                    </p>
                </div>
            </section>
            {data?.length > 0 ?
                <div>
                    <div className="mt-8 mb-8 grid grid-cols-1 md:grid-cols-3">
                        {data?.map((el: any, i: number) => <CardSubscribe subscribe={subscribe}
                                                                          setSubscribe={setSubscribe}
                                                                          key={i}
                                                                          el={el}/>
                        )}
                    </div>
                </div> :
                <div style={{textAlign: 'center', fontSize: 32, fontWeight: 600, marginTop: 32}}>Нет доступных
                    подписок</div>
            }
        </section>
    );
};

export default ListSubscribes;
