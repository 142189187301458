import React from 'react';

const Page = (props: any) => {
    return (
        <main style={{maxWidth: 1200, marginInline: "auto", ...props.style}}>
            {props.children}
        </main>
    );
};

export default Page;