import React, {useContext, useEffect, useRef, useState} from 'react';
import AuthContext from "../../../service/context/AuthProvider";
import useSetMeta from "../../../service/hooks/useSetMeta";
import {getCoursesPage, getPurchasedBlocks} from "../../../service/api/coursesApi";
import {Link} from "react-router-dom";
import BuyCourse from "../BuyCourse";
import Accordion from "../components/accordion/Accordion";
import classes from "./asideMirror.module.css"
import useMobile from "../../../service/hooks/useMobile";
import useCourseInfo from "../../../service/hooks/useCourseInfo";
import CoursePlayer from "../CoursePlayer";
import face from "./img/face.jpg"
import ChatLink from "../../../etc/ChatLink";
import ReviewsCarousel from "../../../widgets/reviews/ReviewsCarousel";
import FormRegister from "../../../stories/FormRegister";
import {buyTinkoff} from "../../../service/api/buyApi";
import review_1 from "./img/review_1.png"
import review_2 from "./img/review_2.png"
import review_3 from "./img/review_3.png"
import review_4 from "./img/review_4.png"
import {SeoHelmet} from "../../../stories/seo/SeoHelmet";
import {ASIDE_MIRROR_PATH} from "../../../service/const/paths";

const AsideMirror = (props: any) => {
    const ref = useRef<HTMLDivElement>(null)
    const isPro = !!props.isPro
    const {auth}: any = useContext(AuthContext);

    const reviews = [review_1, review_2, review_3, review_4]

    const buyCourse = async () => {
        if (!localStorage.getItem("token")) {
            if (ref?.current) {
                ref.current?.scrollIntoView({behavior: "smooth", block: "center"});
            }
        } else {
            const response = await buyTinkoff(
                localStorage.getItem("token"),
                COURSEURL
            );
            if (response?.data) {
                if (response.data) {
                    window.location.replace(response.data);
                }
            }
        }
    };

    // console.log(showPlayer);
    const COURSEURL = isPro ? "aside-mirror-pro" : "aside-mirror"; //* CHANGE THIS WHEN COPY PASTING
    const CONTENT_COURSE_URL = "aside-mirror"


    const STEPS = [
        {latin: "I", step: "Введение в методику и начало работы"},
        {latin: "II", step: "Человек и его место в мире: основные мировоззренческие вопросы"},
        {latin: "III", step: "О смысле жизни"},
        {latin: "IV", step: "Об образовании "},
        {latin: "V", step: "О призвании и профессии"},
        {latin: "VI", step: "О повседневной жизни"},
        {latin: "VII", step: "Практика и закрепление"},
    ];

    const [courses, setCourses] = useState<ICoursesPage | []>([]);
    const [course, setCourse] = useState<any>({});

    interface ICoursesPage {
        courseId: number;
        courseName: string;
        courseUrlid: string;
        currentPrice: any;
        description: string;
        oldPrice: any;
        srcPicturePage: string;
    }

    useEffect(() => {
        const fetchCourses = async () => {
            const responseCourses = await getCoursesPage();
            // console.log(responseCourses);
            if (responseCourses.status === 200) {
                setCourses(responseCourses.data);
                setCourse(
                    await responseCourses.data.filter((course: ICoursesPage) => {
                        return course.courseUrlid === COURSEURL;
                    })[0]
                );
            }
        };
        fetchCourses();
    }, [COURSEURL, isPro]);

    // console.log(course);

    //!* getting button names and info (not content)
    const [courseInfo] = useCourseInfo(COURSEURL);
    // console.log(courseInfo);

    //!*purchased button

    const [purchasedBlocks, setPurchasedBlocks] = useState<any>(null);

    useEffect(() => {
        const fetchPurchasedBlocks = async () => {
            if (localStorage.getItem("token")) {
                //!only if autherized
                const response = await getPurchasedBlocks(
                    localStorage.getItem("token"),
                    CONTENT_COURSE_URL
                );
                if (response.data.error === "Курс не был куплен") {
                    setPurchasedBlocks(null);
                    return;
                }
                if (response.data) {
                    setPurchasedBlocks(response.data);
                    // console.log(response.data);
                }
            } else return;
        };
        try {
            fetchPurchasedBlocks();
        } catch {
            console.log("oops");
        }
    }, [CONTENT_COURSE_URL, isPro]);

    let showPlayer = false;
    if (auth && localStorage.getItem("token") && purchasedBlocks) {
        showPlayer = true;
    }

    function onReload() {
        setTimeout(() => {
            window.location.reload()
        }, 1)
    }

    const isMobile = useMobile()

    function CourseLanding() {
        return (
            <>
                <div className="course-description">
                    <p className="descr-string">Описание курса</p>
                    <p className="descr-text-main-1" style={{marginBottom: "3em"}}>
                        Добро пожаловать в проект “По ту сторону зеркала”! Присоединяйтесь к нам и станьте участником
                        путешествия внутрь себя.
                        <br/><br/>
                        На протяжении многих лет мы исследовали и развивали концепции, заложенные тысячелетними
                        традициями, и теперь с гордостью представляем вам уникальную возможность использовать их
                        мудрость и методы, адаптированные к современным реалиям.
                        <br/><br/>
                        <Accordion head={"Подробное описание"} subHead={"Довольны ли вы последним часом вашей жизни?"}
                                   content={
                                       <p>
                                           Довольны ли вы последним часом вашей жизни? А
                                           последним днем? Неужели суета повседневности с ее
                                           требованиями борьбы за «место под солнцем» – это и есть
                                           жизнь в человеческом смысле? Та ли это жизнь, которую вы
                                           для себя хотели?
                                           <br/>
                                           <br/>
                                           В глубине души каждый человек чувствует, ради чего он на
                                           самом деле должен жить. Каждый человек, так или иначе,
                                           держит в голове наилучший жизненный сценарий, который
                                           он хотел бы воплотить в реальность. Современный мир
                                           ставит для этого множество преград, преодолеть которые
                                           можно путем обращения к мудрости тысячелетних традиций
                                           и практическим упражнениям, навсегда изменив качество
                                           своей жизни.
                                           <br/>
                                           <br/>
                                           На протяжении многих лет мы исследовали и развивали концепции, заложенные
                                           тысячелетними традициями, и теперь с гордостью представляем вам уникальную
                                           возможность использовать их мудрость и методы, адаптированные к современным
                                           реалиям.
                                           <br/>
                                           <br/>
                                           “По ту сторону зеркала” предоставляет уникальную возможность исследования
                                           собственного “я”. Мы подробно раскроем все необходимые инструменты и разберем
                                           каждый случай индивидуально.
                                       </p>
                                   }/>
                    </p>

                    <p className="descr-what-you-learn-p">Что вас ждет</p>
                    <ul className={classes.magicUl}>
                        <li>Глубокое понимание себя и других: Раскройте все тайны внутреннего мира человека.</li>
                        <li>Постоянная собранность: Научитесь проживать каждое мгновение с полной собранностью,
                            преодолевая беспокойство и находя гармонию даже в самых стрессовых моментах.
                        </li>
                        <li>Путь самопознания: Мы проведем вас через практические методы, помогающие исследовать себя,
                            свои привычки и потенциал, чтобы стать лучшей версией себя.
                        </li>
                        <li>Трансформация сознания: Откроем вам двери к новому уровню мышления и восприятия, которые
                            позволят вам видеть мир в более глубоком свете.
                        </li>
                    </ul>

                    <p className="descr-what-you-learn-p" style={{marginTop: 120}}>Чему вы научитесь</p>
                    <div className={classes.whatToLearn}>
                        <ul className={classes.magicUl}>
                            <li>Справляться с восприятием событий в любой ситуации</li>
                            <li>Каждый раз формировать максимально выгодные решения</li>
                            <li>Определить и реализовать наилучший жизненный сценарий</li>
                        </ul>
                        <ul className={classes.magicUl}>
                            <li>Находить выход из любой ситуации</li>
                            <li>Предотвращать деструктивные последствия в жизни</li>
                            <li> Узнаете как действовать дальше в любой ситуации, избегать тупиков и адаптировать все
                                окружение конкретно под ваши цели
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="course-description" style={{marginTop: "4em"}}>
                    <p className="descr-text-main-1" style={{marginTop: "1em"}}>
                        Мы не только рассказываем и показываем как надо делать,
                        мы создаем нужные сообщества и соединяем вас с нужными
                        людьми. Мы убеждены, что знания и социальные связи -
                        самые важные ресурсы.
                    </p>
                </div>

                <div className="course-program">
                    <h2>Программа курса</h2>
                    <div className="course-program-blocks">
                        <Accordion head={"Введение в методику и начало работы"} headStyle={{fontSize: "1.4em"}}
                                   content={
                                       <ol className={classes.programOl}>
                                           <li>Раскрытие методики нашей работы</li>
                                           <li>Объяснение структуры курса</li>
                                           <li>Советы по прохождению курса</li>
                                       </ol>
                                   }/>

                        <Accordion head={"Исследование причин возможных проблем, методы их точного установления"}
                                   headStyle={{fontSize: "1.4em"}} content={
                            <ol className={classes.programOl}>
                                <li>Кто такой человек: его сущность и личность</li>
                                <li>Понимание мира: логическая модель мира</li>
                                <li>Как определяется место человека в мире?</li>
                            </ol>
                        }/>

                        <Accordion head={"Смысл жизни. Практика разрешения проблем"} headStyle={{fontSize: "1.4em"}}
                                   content={
                                       <ol className={classes.programOl}>
                                           <li>Что такое смысл? Объективность смысла жизни и его определение</li>
                                           <li>Метафизика смысла. Исследование понимания смысла древними традициями.
                                           </li>
                                           <li>Как определить смысл жизни каждому человеку?</li>
                                           <li>Как начать выстраивать свою жизнь в гармонии со смыслом.</li>
                                       </ol>
                                   }/>

                        <Accordion head={"Образование. Практика разрешения проблем"} headStyle={{fontSize: "1.4em"}}
                                   content={
                                       <ol className={classes.programOl}>
                                           <li>Что делать с образованием? Как определить его необходимость?</li>
                                           <li>Как следует выстраивать свое образование?</li>
                                           <li>О среднем и высшем образовании: как его проходить и кому оно нужно?</li>
                                       </ol>
                                   }/>

                        <Accordion head={"Работа и профессиональное дело. Практика разрешения проблем"}
                                   headStyle={{fontSize: "1.4em"}} content={
                            <ol className={classes.programOl}>
                                <li>Как определить свое призвание?</li>
                                <li>Каким образом выстраивать свой профессиональный путь?</li>
                                <li>Прогноз востребованности будущих профессий.</li>
                                <li>Смысл профессии и ее сакральное измерение.</li>
                            </ol>
                        }/>

                        <Accordion head={"Повседневная жизнь и сила привычки"} headStyle={{fontSize: "1.4em"}} content={
                            <ol className={classes.programOl}>
                                <li>Чем опасна наша повседневность?</li>
                                <li>Как правильно выстраивать свою ежедневную рутину?</li>
                                <li>Как справляться с конфликтными ситуациями и достигать своих целей в сложных
                                    ситуациях?
                                </li>
                            </ol>
                        }/>

                        <Accordion head={"Заключение и практика закрепление"} headStyle={{fontSize: "1.4em"}} content={
                            <ol className={classes.programOl}>
                                <li>Большое количество практического материала по всем пройденным вопросам.</li>
                                <li>Все необходимые упражнения с комментариями.</li>
                            </ol>
                        }/>
                    </div>
                </div>

                <div className={`course-description ${classes.videoContainer}`} style={{marginTop: "2em"}}>
                    <iframe
                        src="https://www.youtube.com/embed/m-FEMuxFSGU"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    />
                </div>
                {/*<div className="course-description" style={{marginTop: "4em"}}>*/}
                {/*    <p className="descr-what-you-learn-p">Тарифы</p>*/}
                {/*    <div className={classes.cardContainer}>*/}
                {/*        <div className={classes.card}>*/}
                {/*            <h3 className={classes.cardHeader}>Базовый</h3>*/}
                {/*            <ul className={classes.cardUl}>*/}
                {/*                <li>Все указанные лекции и дополнительные материалы</li>*/}
                {/*                <li>Вся необходимая теоретическая информация</li>*/}
                {/*                <li>Полный список практических упражнений и задач</li>*/}
                {/*                <li>Возможность персональной консультации с разбором конкретно вашей ситуации</li>*/}
                {/*            </ul>*/}
                {/*            <div className={classes.changePlan}>*/}
                {/*                {isPro ? <Link to={"/courses/aside-mirror"} onClick={onReload}>Выбрать базовый</Link> :*/}
                {/*                    <div>✔️ Выбран базовый</div>}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className={classes.card}>*/}
                {/*            <div style={{display: "flex", alignItems: "center", flexWrap: "wrap"}}>*/}
                {/*                <h3 className={classes.cardHeader}>Продвинутый</h3>*/}
                {/*            </div>*/}
                {/*            <ul className={classes.cardUl}>*/}
                {/*                <li>Все указанные лекции и дополнительные материалы</li>*/}
                {/*                <li>Вся необходимая теоретическая информация</li>*/}
                {/*                <li>Полный список практических упражнений и задач</li>*/}
                {/*                <li style={{fontWeight: 700}}>Доступ к телеграм-каналу с авторами курса с постоянной*/}
                {/*                    публикацией новых материалов*/}
                {/*                </li>*/}
                {/*                <li style={{fontWeight: 700}}>Постоянная персональная работа с преподавателями вплоть до*/}
                {/*                    доведения до результата*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*            <div className={classes.changePlan}>*/}
                {/*                {!isPro ? <Link to={"/courses/aside-mirror-pro"} onClick={onReload}>Выбрать*/}
                {/*                    продвинутый</Link> : <div>✔️ Выбран продвинутый</div>}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="course-description" style={{padding: "0 20px"}}>
                    <p className="descr-what-you-learn-p">Часто задаваемые вопросы</p>
                    <Accordion head={"На каком устройстве можно проходить курс?"} content={
                        <p>
                            На любом. Наша платформа прекрасно адаптирована под все
                            устройства. Вам нужен лишь доступ к интернету.
                        </p>
                    }/>

                    <Accordion head={"Что нужно иметь для прохождения курса?"} content={
                        <p>
                            Никакой специальной подготовки к курсу не нужно - все
                            необходимое вы получите в процессе обучения. От вас ожидается
                            лишь желание достичь результата.
                        </p>
                    }/>

                    <Accordion head={"Как выглядит курс изнутри?"} content={
                        <p>
                            После приобретения, данный курс будет доступен вам в личном
                            кабинете. Там вы найдете все необходимые обучающие
                            материалы.
                        </p>
                    }/>

                    <Accordion head={"Сколько времени займет прохождение курса?"} content={
                        <p>
                            Зависит от вашей вовлеченности. Большая часть курса отводится
                            практической работе и выполнению специальных упражнений,
                            поэтому его усвоение занимает больше времени, чем
                            длительность теоретических лекций.
                        </p>
                    }/>

                    <Accordion head={"Как проходит индивидуальная работа?"} content={
                        <p>
                            При приобретении курса у вас есть возможность записаться на индивидуальную консультацию, где
                            мы разберем вашу проблему или любую интересующую вас тему один на один. Кроме того, у вас
                            будет возможность записаться на серию таких консультаций. Как это сделать - рассказывается в
                            самой программе.
                        </p>
                    }/>

                    <Accordion head={"Откуда вы берете такие знания? На чем они основаны? Каковы ваши источники?"}
                               content={
                                   <p>
                                       В проекте “По ту сторону зеркала” мы ставим перед собой довольно амбициозные цели
                                       и этот вопрос более чем справедлив.

                                       <br/>
                                       <br/>
                                       Источники нашего знания таковы:

                                       <ul style={{listStyleType: "circle", marginLeft: "1em", marginTop: "1em"}}>
                                           <li>
                                               Обширные академические связи. Будучи выходцами из Санкт-Петербургского
                                               государственного университета, мы - основная команда проекта - сохранили
                                               связи в научных кругах России. Мы также сотрудничаем с исследователями из
                                               Кембриджа и Манчестера.
                                           </li>
                                           <li>
                                               Наши собственные исследовательские группы проводят уникальные и
                                               новаторские исследования. К этой практике мы прибегаем в том случае,
                                               когда в какой-либо рассматриваемой нами теме остаются белые пятна.
                                           </li>
                                           <li>
                                               Сбор знаний по всему миру. Если нам необходим сбор знаний, которые
                                               выходят за рамки открытого доступа, мы путешествуем в те места, где их
                                               можно собрать. К примеру, курсу “По ту сторону зеркала” предшествовала
                                               многолетняя работа - она началась задолго до официального учреждения
                                               проекта. Использовался опыт всех членов нашей команды. Кроме того, в
                                               поисках сакральных знаний мы бывали во многих закрытых местах, беседовали
                                               и состояли в переписке с философами и учеными различных направлений.
                                           </li>
                                       </ul>
                                   </p>
                               }/>
                </div>

                <div className="buy-course-end">
                    <BuyCourse COURSEURL={COURSEURL} buyCourse={buyCourse}/>
                </div>

                <ReviewsCarousel maxCount={3} reviews={reviews}/>
            </>
        )
    }

    return (
        <>
            <SeoHelmet
                title={'Выход на новый уровень с онлайн-курсом \"По ту сторону зеркала\"'}
                description={"На протяжении многих лет мы исследовали и развивали концепции, заложенные тысячелетними традициями, и теперь с гордостью представляем вам уникальную возможность использовать их мудрость и методы, адаптированные к современным реалиям."}
                url={ASIDE_MIRROR_PATH}
                img={`url(${face})`}
            />
            <div className="course-nav">
                <Link to="/">
                    <img width="150px" height="85px" src="../../logo.png" alt=""/>
                </Link>
            </div>
            {
                course.courseId ? (
                    <div className="course-container">
                        <div className="course-hero"
                             style={{
                                 backgroundImage: `url(${face})`,
                             }}
                        >
                            <div className="course-hero-info">
                                <p className="online-course-text">Онлайн курс</p>
                                <h1 style={isMobile ? {fontSize: "0.8em"} : {}}>{course.courseName.toUpperCase()}</h1>
                                <BuyCourse COURSEURL={COURSEURL} buyCourse={buyCourse}/>
                            </div>
                        </div>
                        {
                            !localStorage.getItem("token") &&
                            <div style={{marginTop: 32}} ref={ref}>
                                <div className="p-2 text-center my-4 md:max-w-[1000px] m-auto">
                                    Для приобритения курса зарегестрируйтесь на сайте
                                </div>
                                <FormRegister discount={courseInfo?.p?.courseOldPrice}
                                              courseOldPrice={courseInfo?.p?.courseOldPrice}
                                              courseCurrentPrice={courseInfo?.p?.courseCurrentPrice}
                                              buyFunc={buyCourse}/>
                            </div>
                        }
                        {showPlayer && (
                            <>
                                <CoursePlayer
                                    purchasedBlocks={purchasedBlocks}
                                    courseURLId={CONTENT_COURSE_URL}
                                />
                                {isPro ?
                                    <ChatLink
                                        chatName="Чат “По ту сторону зеркала (non)human”"
                                        chatLink="https://t.me/+8mLaNdEcNJllNzJi"
                                    /> :
                                    null
                                }
                            </>
                        )}

                        {!showPlayer && <CourseLanding/>}
                    </div>
                ) : (
                    <div>Загрузка...</div>
                )}
        </>
    );
};

export default AsideMirror;
