import React, {useContext, useEffect, useRef, useState} from 'react';
import {SeoHelmet} from "../../../stories/seo/SeoHelmet";
import {HOW_READ_PEOPLE_PATH} from "../../../service/const/paths";
import album from "./assets/album.png";
import {Link} from "react-router-dom";
import BuyCourse from "../BuyCourse";
import FormRegister from "../../../stories/FormRegister";
import CoursePlayer from "../CoursePlayer";
import useMobile from "../../../service/hooks/useMobile";
import useCourseInfo from "../../../service/hooks/useCourseInfo";
import AuthContext from "../../../service/context/AuthProvider";
import {buyCourse} from "../../../service/functions";
import classes from "../asideMirror/asideMirror.module.css";
import ChatLink from "../../../etc/ChatLink";
import {getPurchasedBlocks} from "../../../service/api/coursesApi";


const LATIN = [
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
    "X",
    "XI",
    "XII",
    "XIII",
    "XIV",
    "XV",
];

const COURSEURL = "how-read-the-people";
const CONTENT_COURSE_URL = "how-read-the-people"


export const HowReadPeople = () => {
    const ref = useRef<HTMLDivElement>(null)
    const isMobile = useMobile()
    const {auth}: any = useContext(AuthContext);
    const [courseInfo] = useCourseInfo(COURSEURL);
    const [purchasedBlocks, setPurchasedBlocks] = useState<any>(null);

    let showPlayer = false;

    if (auth && localStorage.getItem("token") && purchasedBlocks) {
        showPlayer = true;
    }

    useEffect(() => {
        const fetchPurchasedBlocks = async () => {
            if (localStorage.getItem("token")) {
                const response = await getPurchasedBlocks(
                    localStorage.getItem("token"),
                    CONTENT_COURSE_URL
                );
                if (response.data.error === "Курс не был куплен") {
                    setPurchasedBlocks(null);
                    return;
                }
                if (response.data) {
                    setPurchasedBlocks(response.data);
                }
            } else return;
        };
        try {
            fetchPurchasedBlocks();
        } catch {
            console.log("oops");
        }
    }, [CONTENT_COURSE_URL]);

    function CourseLanding() {

        return (
            <>
                <div className="course-description">
                    <p className="descr-string">Описание курса</p>
                    <p className="descr-text-main-1" style={{marginBottom: "3em"}}>
                        Понимание человеческой природы открывает многие возможности. К числу таковых относится
                        применение навыка убеждения, который является, вероятно, наиболее полезным умением среди так
                        называемых “мягких навыков”. Овладев им в совершенстве возможно филигранно вести переговоры и
                        добиваться от от окружающих того, чего вы хотите.
                        <br/><br/>
                        Хотели бы вы научиться убеждать людей в том, что вам нужно? Хотели бы вы распознавать любую
                        манипуляцию и противостоять ей? – Если да, то эта программа специально для вас!
                    </p>

                    <p className="descr-what-you-learn-p">Что вас ждет</p>
                    <ul className={classes.magicUl}>
                        <li>Обучение методам практической психологии.</li>
                        <li>Обучение искусству вести переговоры.</li>
                        <li>Множество полезных упражнений.</li>
                        <li>Глубокое погружение в фундамент человеческой природы.</li>
                    </ul>

                    <div className="descr-mid-container">
                        <div className="descr-how-many" style={{width: 420}}>

                        </div>
                        <div className="descr-who-container">
                            <div className="descr-who">
                                <img src="../faces/nikita.jpg" alt=""/>
                                <div>
                                    <p className="descr-who-reads">курс читает</p>
                                    <h2 className="descr-who-name">НИКИТА ЗВЕРЕВ</h2>
                                    <p className="descr-who-job">философ, психолог, основатель академии “Нус”</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="present">
                        <div>
                            <h2>Подарок прямо сейчас!</h2>
                            <p>Получите прямо сейчас доступ к лекции о практической психологии и множеству других
                                полезных материалов по теме</p>

                        </div>
                        <div>
                            <img src="../gift_icon.png" alt=""/>
                        </div>
                    </div>

                    <div className="course-program">
                        <h2>Программа курса</h2>
                        <div className="course-program-blocks">
                            {courseInfo.p ? (
                                courseInfo.p.b.map((element: any, index: any) => {
                                    return (
                                        <div key={element["blockId"]}>
                                            <h3 className="block-name">
                                                {LATIN[index]}&nbsp;&nbsp;&nbsp;
                                                {element["blockName"]}
                                            </h3>
                                            <div className="subblocks">
                                                {element.i.map((elementInner: any) => {
                                                    return (
                                                        <p key={elementInner["blockInfoBlockInfoId"]}>
                                                            — {elementInner["blockInfoBlockInfoName"]}
                                                        </p>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div>Загрузка...</div>
                            )}
                        </div>

                        <div className="buy-course-end">
                            <BuyCourse COURSEURL={COURSEURL} buyCourse={() => buyCourse(ref, COURSEURL)}/>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <SeoHelmet
                title={'“Как читать людей?” - Психологический онлайн-курс'}
                description={"Хотели бы вы научиться убеждать людей в том, что вам нужно? Хотели бы вы распознавать любую манипуляцию и противостоять ей? - Если да, то эта программа специально для вас!"}
                url={HOW_READ_PEOPLE_PATH}
                img={`url(${album})`}
            />

            <div className="course-nav">
                <Link to="/">
                    <img width="150px" height="85px" src="../../logo.png" alt=""/>
                </Link>
            </div>

            {
                courseInfo.courseId ? (
                    <div className="course-container">
                        <div className="course-hero"
                             style={{
                                 backgroundImage: `url(${album})`,
                             }}
                        >
                            <div className="course-hero-info">
                                <p className="online-course-text">Онлайн курс</p>
                                <h1 style={isMobile ? {fontSize: "0.8em"} : {}}>{courseInfo.courseName.toUpperCase()}</h1>
                                <BuyCourse COURSEURL={COURSEURL} buyCourse={() => buyCourse(ref, COURSEURL)}/>
                            </div>
                        </div>
                        {
                            !localStorage.getItem("token") &&
                            <div style={{marginTop: 32}} ref={ref}>
                                <div className="p-2 text-center my-4 md:max-w-[1000px] m-auto">
                                    Для приобритения курса зарегестрируйтесь на сайте
                                </div>
                                <FormRegister
                                    discount={courseInfo?.p?.courseOldPrice}
                                    courseOldPrice={courseInfo?.p?.courseOldPrice}
                                    courseCurrentPrice={courseInfo?.p?.courseCurrentPrice}
                                    buyFunc={() => buyCourse(ref, COURSEURL)}
                                />
                            </div>
                        }
                        {
                            showPlayer && (
                                <>
                                    <CoursePlayer
                                        purchasedBlocks={purchasedBlocks}
                                        courseURLId={CONTENT_COURSE_URL}
                                    />

                                    {/*<ChatLink*/}
                                    {/*    chatName="Чат “По ту сторону зеркала (non)human”"*/}
                                    {/*    chatLink="https://t.me/+8mLaNdEcNJllNzJi"*/}
                                    {/*/>*/}
                                </>
                            )
                        }

                        {!showPlayer && <CourseLanding/>}
                    </div>
                ) : (
                    <div>Загрузка...</div>
                )}
        </>
    );
};
