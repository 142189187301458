import "./App.css";
import React from "react";
import {Routing} from "./service/router/Routing";
import {HelmetProvider} from "react-helmet-async";
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "./service/context/AuthProvider";
import {HOME_PATH} from "./service/const/paths";

function App() {

    return (
        <HelmetProvider>
            <BrowserRouter basename={HOME_PATH}>
                <AuthProvider>
                    <div className="App">
                        <Routing/>
                    </div>
                </AuthProvider>
            </BrowserRouter>
        </HelmetProvider>
    );
}

export default App;
