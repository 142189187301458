import React, {useEffect, useState} from "react";
import {getCoursesPage} from "../../service/api/coursesApi";
import Mrgn from "../../layout/Mrgn";
import "./style.css";
import CourseCard from "../../stories/CourseCard";
import {ICoursesPage} from "../../service/types/interfaces";
import {AllReviews} from "../../widgets/allReviews/ui/AllReviews";

function Courses() {
	const [courses, setCourses] = useState<any>([]);

	useEffect(() => {
		const fetchCourses = async () => {
			const responseCourses = await getCoursesPage();
			if (responseCourses.status === 200) {
				setCourses(responseCourses.data);
			}
		};
		fetchCourses();
	}, []);

	const coursesMeow = courses.map((element: ICoursesPage) => <CourseCard element={element}/>);

	return (
		<>
			<Mrgn />
			<div className="courses-page">
				<div className="courses-container">{coursesMeow}</div>
			</div>

			<AllReviews/>
		</>
	);
}

export default Courses;
