import React, {useState} from 'react';
import {BsChevronDown, BsChevronUp} from "react-icons/bs";
import classes from "./accordion.module.css"

const Accordion = ({head, subHead, content, headStyle}: any) => {
    const [isClosed, setIsClosed] = useState<boolean>(true);

    const closeContent = () => {
        setIsClosed((prev) => !prev);
    };

    return (
        <>
            <div className={classes.accordionContainer}>
                <div onClick={closeContent} className={classes.accordion}>
                    <div className={classes.jobContainer}>
                        <p className="job-name  pb-5" style={headStyle}>{head}</p>
                        <p className={classes.jobSubHead}>{subHead}</p>
                    </div>
                    <div>
                        {isClosed ? <BsChevronDown size={30}/> : <BsChevronUp size={30}/>}
                    </div>
                </div>
                <div
                    className={
                        isClosed ? `hidden ${classes.accordionContent}` : `${classes.accordionContent}`
                    }
                    style={{padding: "2rem"}}
                >
                    <div className="job-description">{content}</div>
                </div>
            </div>
        </>
    );
};

export default Accordion;
