import axios from "axios";
import {SUBSCRIBE_API, USER_API} from "../const/api";

const buyApi = axios.create({
    baseURL: USER_API,
});

const buySubscribeApi = axios.create({
    baseURL: SUBSCRIBE_API,
});


export const sendJobs = async (
    token: any,
    name: string,
    contact: string,
    role: string,
    about: string
) => {
    return buyApi.post(
        "/Vacancy",
        JSON.stringify({
            token,
            contact,
            role,
            about,
            name,
        }),
        {
            headers: {
                "Content-Type": "application/json",
            },
        }
    );
};

export const buyTinkoff = async (
    token: string | null,
    courseURLId: string,
    blockURLId?: string
) => {
    let link = `/GeneratePurchaseLink?token=${token}&courseURLId=${courseURLId}`;
    if (blockURLId) link = link + `&${blockURLId}`;
    return buyApi.get<string>(link);
};

export const validateTinkoff = async (userId: string, orderId: string) => {
    try {
        const response = await buyApi.post(
            "/ValidatePurchase",
            JSON.stringify({
                userId,
                orderId,
            }),
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response.data;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            if (error.response?.data.error) {
                return error.response;
            }
        }
    }
};

export const validateTinkoffSubscribe = async (userId: string, orderId: string) => {
    try {
        const response = await buySubscribeApi.post(
            "/ValidateSubscribePurchase",
            JSON.stringify({
                userId,
                orderId,
            }),
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response.data;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            if (error.response?.data.error) {
                return error.response;
            }
        }
    }
};

