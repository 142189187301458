import React from 'react';
import logo from "./loader.png"
import "./loader.css"

const Loader = () => {
    return (
        <div className={"container-logo"}>
            <img alt={"logo"} className={"loader"} style={{width: 300}} src={logo}/>
        </div>
    );
};

export default Loader;
