import React, {useEffect, useState} from "react";
import Mrgn from "../../layout/Mrgn";
import "../../styles/componentStyles/account/resetPassword.css";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import {resetPassEmailConfirm, resetPassNewPass} from "../../service/api/authApi";

function ResetPassword() {
	const navigate = useNavigate();
	const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
	const [isComplete, setIsComplete] = useState<boolean>(false);
	const [isSent, setIsSent] = useState(false);
	const [emailError, setEmailError] = useState("");
	const [resetError, setResetError] = useState("");
	const [searchParams, setSearchParams] = useSearchParams();
	useEffect(() => {
		console.log(searchParams.get("code"));
		console.log(searchParams.get("userid"));
		if (searchParams.get("code") && searchParams.get("userid")) {
			setIsEmailSent(true);
		}
	}, []);
	const onEmailSubmit: SubmitHandler<any> = async (data) => {
		const response = await resetPassEmailConfirm(data.email);
		if (response.data.error) {
			console.log(response.data.error);
			setEmailError(response.data.error);
		} else {
			console.log(response);
			setIsSent(true);
		}
	};
	const onSubmit: SubmitHandler<any> = async (data) => {
		const userId = searchParams.get("userid")!.toString();
		const code = searchParams.get("code")!.toString();
		const response = await resetPassNewPass(
			userId,
			data.password,
			data.confirmPassword,
			code
		);
		if (response.data.error) {
			setResetError("Произошла ошибка при сбросе пароля, повторите попытку.");
		} else {
			setIsComplete(true);
			setTimeout(() => {
				navigate("/login");
			}, 4000);
		}
	};
	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm();
	if (!isComplete) {
		return (
			<>
				<Mrgn />
				<div className="reset-password-container">
					<h1>Восстановление пароля</h1>
					{!isEmailSent ? (
						<div className="reset-before-sending-email">
							<div className="form-container">
								<form onSubmit={handleSubmit(onEmailSubmit)}>
									<div className="input-group">
										<div className="label-group">
											<label htmlFor="email-field">E-mail</label>
											<div className="required-error">
												{errors?.email?.type == "required" && (
													<span className="field-error">
														(обязательное поле!)
													</span>
												)}
											</div>
										</div>

										<input
											id="email-field"
											{...register("email", {
												required: true,
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
													message: "С почтовым адресом что-то не так",
												},
											})}
										/>

										<p style={{ textAlign: "center" }}>
											{isSent &&
												"Письмо отправлено, проверьте почту. Возможно, придется подождать его пару минут."}
										</p>
										<div className="input-error">
											<p className="under-input">{emailError}</p>
											{errors?.email?.message && (
												<p>{errors.email?.message.toString()}</p>
											)}
										</div>
									</div>
									<button className="button">
										<input type="submit" />
										<p>отправить письмо</p>
									</button>
									<i style={{paddingTop: 12}}>Письмо может прийти в «спам»</i>
									{/*<Link className="forget-pass" to="/login">
										назад
									</Link>*/}
								</form>
							</div>
						</div>
					) : (
						<div>
							<div className="form-container">
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="input-group">
										<div className="label-group">
											<label htmlFor="pass-field">Новый пароль</label>
											<div className="required-error">
												{errors?.password?.type == "required" && (
													<span className="field-error">
														(обязательное поле!)
													</span>
												)}
											</div>
										</div>

										<input
											id="pass-field"
											type="password"
											{...register("password", {
												required: true,
												minLength: 6,
												maxLength: 100,
											})}
										/>
										<div className="input-error">
											{errors?.password?.type == "minLength" && (
												<p>Слишком короткий пароль (минимум 6 символов)</p>
											)}
											{errors?.password?.type == "maxLength" && (
												<p>
													Слишком длинный пароль, максимум 100 символов ( •_•)
												</p>
											)}
										</div>
									</div>

									<div className="input-group">
										<div className="label-group">
											<label htmlFor="pass-field">Подтверждение пароля</label>
											<div className="required-field">
												{errors?.confirmPassword?.type == "required" && (
													<span className="field-error">
														(обязательное поле!)
													</span>
												)}
											</div>
										</div>

										<input
											id="pass-field"
											type="password"
											{...register("confirmPassword", {
												required: true,
												validate: (val: string) => {
													if (watch("password") != val) {
														return "Пароли не совпадают";
													}
												},
											})}
										/>
										<div className="input-error">
											<p className="under-input">{resetError}</p>
										</div>
										{errors?.confirmPassword?.message ==
											"Пароли не совпадают" && (
											<p>Пароли не совпадают</p>
											// Работает, но ))))))))))
										)}
									</div>

									<button className="button">
										<input type="submit" />
										<p>сохранить</p>
									</button>
								</form>
							</div>
						</div>
					)}
				</div>
			</>
		);
	} else {
		return (
			<>
				<Mrgn />
				<div>
					<h2 style={{ margin: "auto", textAlign: "center" }}>
						восстановление пароля успешно завершено, сейчас вы будете
						перенаправлены на страницу входа
					</h2>
				</div>
			</>
		);
	}
}

export default ResetPassword;
