import "../../../styles/componentStyles/coursesPage/course.css";
import {fixImgSrc, getCoursesPage, getPurchasedBlocks} from "../../../service/api/coursesApi";
import React, {useContext, useEffect, useRef, useState} from "react";
import useCourseInfo from "../../../service/hooks/useCourseInfo";
import CoursePlayer from "../CoursePlayer";
import AuthContext from "../../../service/context/AuthProvider";
import BuyCourse from "../BuyCourse";
import {Link} from "react-router-dom";
import ChatLink from "../../../etc/ChatLink";
import ReviewsCarousel from "../../../widgets/reviews/ReviewsCarousel";
import useSetMeta from "../../../service/hooks/useSetMeta";
import {buyTinkoff} from "../../../service/api/buyApi";
import FormRegister from "../../../stories/FormRegister";
import review_1 from "./img/review_1.png"
import review_2 from "./img/review_2.png"
import review_3 from "./img/review_3.png"
import review_4 from "./img/review_4.png"

function BusinesTech() {
    const {auth}: any = useContext(AuthContext);
    const ref = useRef<HTMLDivElement>(null)

    useSetMeta({
        title: "Онлайн-курс “Искусство вести дела",
        description: "Ведение предпринимательства - это сложное и комплексное искусство. На курсе мы подробно разбираем интересные кейсы из разных сфер и покажем на практике как каждый раз выстраивать самые успешные стратегии."
    })

    const reviews = [review_1, review_2, review_3, review_4]

    const buyCourse = async () => {
        if (!localStorage.getItem("token")) {
            if (ref?.current) {
                ref.current?.scrollIntoView({behavior: "smooth", block: "center"});
            }
        } else {
            const response = await buyTinkoff(
                localStorage.getItem("token"),
                COURSEURL
            );
            if (response?.data) {
                if (response.data) {
                    window.location.replace(response.data);
                }
            }
        }
    };

    // console.log(showPlayer);
    const COURSEURL = "busines-in-the-era-of-smart-technologies"; //* CHANGE THIS WHEN COPY PASTING
    const LATIN = [
        "I",
        "II",
        "III",
        "IV",
        "V",
        "VI",
        "VII",
        "VIII",
        "IX",
        "X",
        "XI",
        "XII",
        "XIII",
        "XIV",
        "XV",
    ];
    const [courses, setCourses] = useState<ICoursesPage | []>([]);
    const [course, setCourse] = useState<any>({});

    interface ICoursesPage {
        courseId: number;
        courseName: string;
        courseUrlid: string;
        currentPrice: any;
        description: string;
        oldPrice: any;
        srcPicturePage: string;
    }

    useEffect(() => {
        const fetchCourses = async () => {
            const responseCourses = await getCoursesPage();
            // console.log(responseCourses);
            if (responseCourses.status === 200) {
                setCourses(responseCourses.data);
                setCourse(
                    await responseCourses.data.filter((course: ICoursesPage) => {
                        return course.courseUrlid === COURSEURL;
                    })[0]
                );
            }
        };
        fetchCourses();
    }, []);

    // console.log(course);

    //* getting button names and info (not content)
    const [courseInfo] = useCourseInfo(COURSEURL);
    // console.log(courseInfo);

    //*purchased button

    const [purchasedBlocks, setPurchasedBlocks] = useState<any>(null);

    useEffect(() => {
        const fetchPurchasedBlocks = async () => {
            if (localStorage.getItem("token")) {
                //!only if autherized
                const response = await getPurchasedBlocks(
                    localStorage.getItem("token"),
                    COURSEURL
                );
                if (response.data.error === "Курс не был куплен") {
                    setPurchasedBlocks(null);
                    return;
                }
                if (response.data) {
                    setPurchasedBlocks(response.data);
                    // console.log(response.data);
                }
            } else return;
        };
        try {
            fetchPurchasedBlocks();
        } catch {
            console.log("oops");
        }
    }, []);

    let showPlayer = false;
    if (auth && localStorage.getItem("token") && purchasedBlocks) {
        showPlayer = true;
    }

    function CourseLanding() {
        return (
            <>
                <div className="course-description">
                    <p className="descr-string">Описание курса</p>

                    <p className="descr-text-main-1" style={{marginBottom: "3em"}}>
                        Ведение предпринимательства - это сложное и комплексное искусство. Именно этому искусству будут
                        обучать наши лучшие преподаватели - настоящие эксперты в своем деле. Вместе с ними вы
                        разберетесь в тончайших мелочах ведения деловых процессов. На курсе мы подробно разбираем
                        интересные кейсы из разных сфер и покажем на практике как каждый раз выстраивать самые успешные
                        стратегии.
                    </p>

                    <p className="descr-text-main-1">
                        Бизнес - это не только деньги, это всегда нечто большее. Бизнес - это искусство, с которым
                        сталкивается каждый человек, независимо от того чем он занимается. И насколько он владеет этим
                        искусством определяет его успешность в нашем мире
                    </p>

                    <div className="descr-mid-container">
                        <div className="descr-how-many">
                            <h2>6</h2>
                            <p>практических лекций</p>
                        </div>
                        <div className="descr-who-container">
                            <div className="descr-who">
                                <img src="../faces/anna_and.png" alt=""/>
                                <div>
                                    {/* <p className="descr-who-reads">курс читают</p> */}
                                    <h2 className="descr-who-name">АННА АНДОРСКАЯ</h2>
                                    <p className="descr-who-job">
                                        начальник отдела сопровождения проектов и НСИ из "Газпром"
                                    </p>
                                </div>
                            </div>
                            <div className="descr-who">
                                <img src="../faces/eliza.png" alt=""/>
                                <div>
                                    {/* <p className="descr-who-reads">курс читают</p> */}
                                    <h2 className="descr-who-name">ЕЛИЗАВЕТА ВИЦЕНКО</h2>
                                    <p className="descr-who-job">
                                        основатель консалтингового агентства BisHelp
                                    </p>
                                </div>
                            </div>
                            <div className="descr-who">
                                <img src="../faces/polina_fotograf.png" alt=""/>
                                <div>
                                    {/* <p className="descr-who-reads">курс читают</p> */}
                                    <h2 className="descr-who-name">ПОЛИНА МАМОНТОВА </h2>
                                    <p className="descr-who-job">
                                        фотограф, дизайнер, эксперт в создании визуальных образов
                                        и продвижении бренда
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="descr-text-main-2">
                        Мы живем в эпоху технологических революций, каждая из которых
                        может затронуть не только какую-либо сферу бизнеса, но также и
                        нашу повседневную жизнь. Появляется все больше различных
                        стартапов, разработки которых уже завтра могут стать для нас
                        обыденностью.
                    </p>
                    {/*<div className="arrow-stuff">*/}
                    {/*    <p className="tech-text" style={{marginTop: 50}}>Технологии в бизнесе:</p>*/}
                    {/*    <div className="arrow-container">*/}
                    {/*        <div>*/}
                    {/*            <p>*/}
                    {/*                технологии блокчейн на предприятие как способ хранения,*/}
                    {/*                передачи и производства данных сокращает издержки на*/}
                    {/*            </p>*/}
                    {/*            <h2>10-20%</h2>*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            <p>*/}
                    {/*                современная CRM система сокращает сроки выхода продукта на*/}
                    {/*            </p>*/}
                    {/*            <h2>10-20%</h2>*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            <p>*/}
                    {/*                передовые технологии информационного моделирования зданий*/}
                    {/*                позволяют сократить затраты на материалы при строительстве*/}
                    {/*                здания на*/}
                    {/*            </p>*/}
                    {/*            <h2>10-20%</h2>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    Какие возможности перед нами открывает технологическое развитие?

                    <p className="descr-what-you-learn-p">Что вы узнаете на курсе?</p>
                    <div className="descr-what-you-learn-container">
                        <div className="descr-what-you-learn-text">
                            <p>как вести деловые процессы в абсолютно разных сферах?</p>
                        </div>
                        <div className="descr-what-you-learn-text">
                            <p>
                                как всегда успешно завершать начатое?
                            </p>
                        </div>
                        <div className="descr-what-you-learn-text">
                            <p>какие инструменты сейчас наиболее актуальны для ведения деловых процессов и как их
                                использовать?</p>
                        </div>
                        <div className="descr-what-you-learn-text">
                            <p>
                                как вести переговоры и стать лидером команды?
                            </p>
                        </div>
                        <div/>
                        <div className="descr-what-you-learn-text">
                            <p>какие возможности перед нами открывает технологическое развитие?</p>
                        </div>
                    </div>
                </div>

                {/*<ChatScreen type={"business"}*/}
                {/*            title={<span>Приобретя этот курс, вы получаете курс <Link to={"/courses/digital-magnate"}>“Цифровой магнат”</Link> в подарок АБСОЛЮТНО БЕСПЛАТНО”</span>}*/}
                {/*/>*/}

                <div className="course-program">
                    <h2>Программа курса</h2>
                    <div className="course-program-blocks">
                        {courseInfo.p ? (
                            courseInfo.p.b.map((element: any, index: any) => {
                                let date = "";
                                if (element.blockReleaseDate) {
                                    date = element.blockReleaseDate;
                                    date.replace("T", " ");
                                }
                                return (
                                    <div key={element["blockId"]}>
                                        <h3 className="block-name">
                                            {LATIN[index]}&nbsp;&nbsp;&nbsp;
                                            {element["blockName"]}&nbsp;&nbsp;
                                            <span className="course-block-date">
													{date &&
                                                        date
                                                            .replace("T", " ")
                                                            .slice(0, -3)
                                                            .replace("-", "/")
                                                            .replace("-", "/")}
												</span>
                                        </h3>
                                        <div className="subblocks">
                                            {element.i.map((elementInner: any) => {
                                                return (
                                                    <p key={elementInner["blockInfoBlockInfoId"]}>
                                                        — {elementInner["blockInfoBlockInfoName"]}
                                                    </p>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div>Загрузка...</div>
                        )}
                    </div>
                </div>
                <div className="buy-course-end">
                    <BuyCourse COURSEURL={COURSEURL} buyCourse={buyCourse}/>
                </div>

                <ReviewsCarousel maxCount={4} reviews={reviews}/>
            </>
        )
    }

    return (
        <>
            {/* <Mrgn /> */}
            <div className="course-nav">
                <Link to="/">
                    <img width="150px" height="85px" src="../../logo.png" alt=""/>
                </Link>
            </div>
            {course.courseId ? (
                <div className="course-container">
                    <div
                        className="course-hero"
                        style={{
                            backgroundImage: `url(${fixImgSrc(
                                "files/course-pictures/busines-in-the-era-of-smart-technologies2.jpg"
                            )})`,
                        }}
                    >
                        <div className="course-hero-info">
                            <p className="online-course-text">Онлайн курс</p>
                            <h1>{course.courseName.toUpperCase()}</h1>
                            <BuyCourse COURSEURL={COURSEURL} buyCourse={buyCourse}/>
                        </div>
                    </div>
                    {
                        !localStorage.getItem("token") &&
                        <div style={{marginTop: 32}} ref={ref}>
                            <div className="p-2 text-center my-4 md:max-w-[1000px] m-auto">
                                Для приобритения курса зарегестрируйтесь на сайте
                            </div>
                            <FormRegister discount={courseInfo?.p?.courseOldPrice}
                                          courseOldPrice={courseInfo?.p?.courseOldPrice}
                                          courseCurrentPrice={courseInfo?.p?.courseCurrentPrice}
                                          buyFunc={buyCourse}/>
                        </div>
                    }
                    {showPlayer && (
                        <>
                            <CoursePlayer
                                purchasedBlocks={purchasedBlocks}
                                courseURLId={COURSEURL}
                            />
                            <ChatLink
                                chatName="Чат “Бизнес на (non)human”"
                                chatLink="https://t.me/+JP5FtX-vWaFmYTVi"
                            />
                        </>
                    )}

                    {!showPlayer && <CourseLanding/>}
                </div>
            ) : (
                <div>Загрузка...</div>
            )}
        </>
    );
}

export default BusinesTech;
