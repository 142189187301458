import "../../styles/home.css";
import Button from "../../components/button/Button";
import CoursesCarousel from "./CoursesCarousel";
import Footer from "../../layout/Footer";
import HTwo from "../../components/headers/HTwo";
import {ASIDE_MIRROR_PATH, HOME_PATH} from "../../service/const/paths";
import Faq from "./faq/Faq";
import {SeoHelmet} from "../../stories/seo/SeoHelmet";
import {AllReviews} from "../../widgets/allReviews/ui/AllReviews";

const Home = () => {

    return (
        <>
            <SeoHelmet
                title={"Академия “НУС” - обучение, курсы, образовательные программы"}
                description={"Фундаментальные курсы по различным направлениям. Разберитесь в философии, психологии, искусстве и истории вместе с нами!"}
                url={HOME_PATH}
                img={"/logo.png"}
                script={`
                        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){
                            (m[i].a=m[i].a||[]).push(arguments)};
                        m[i].l=1*new Date();
                        for (var j = 0; j < document.scripts.length; j++) {
                            if (document.scripts[j].src === r) { return; }
                        }
                        k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
                    
                        ym(98308070, "init", {
                            clickmap:true,
                            trackLinks:true,
                            accurateTrackBounce:true
                        });
                    `}
                noscript={`<div><img src="https://mc.yandex.ru/watch/98308070" style={{position: 'absolute', left: '-9999px'}} alt=""/></div>`}
            />
            <div className="featured-course">
                <div className="featured-course-inner">
                    <div className="featured-course-first">
                        <div className="featured-type">
                            <p className="text-center">Академия НУС</p>
                        </div>
                        <div className="featured-course-info">
                            <h4>Добро пожаловать, дорогой гость!</h4>
                            <p>Начни путь познания прямо сейчас</p>
                        </div>
                    </div>
                    <Button to={ASIDE_MIRROR_PATH} text={"Подробнее"}/>
                </div>
            </div>

            <div className="courses-home">
                <HTwo style={{marginBottom: "2em"}}>Курсы</HTwo>
                <CoursesCarousel/>
                <Button to={"courses"} text={"больше курсов"}></Button>
            </div>

            <AllReviews/>

            <Faq/>

            <div className="home-podcasts">
                <p style={{fontWeight: 300}}>Эфиры</p>
                <Button to={"https://www.youtube.com/@nonhumanproj"} text={"Подробнее"}/>
            </div>
            <div id="vk_podcast"></div>

            <Footer/>
        </>
    );
};

export default Home;
