import Mrgn from "../../layout/Mrgn";
import "../../styles/componentStyles/login.css";
import FormRegister from "../../stories/FormRegister";

const RegisterPage = () => {

    return (
        <div>
            <Mrgn/>
            <FormRegister/>
        </div>
    )
}

export default RegisterPage;
