import React from 'react';
import {testResults} from "./questions";
import Button from "../../components/button/Button";

const TestResult = (props) => {
    const {counts} = props

    let topKey="management"
    let max = counts[topKey]
    Object.keys(counts).forEach(key=>{
        if (counts[key]>max) {
            max=counts[key]
            topKey=key
        }
    })

    //console.log({topKey, counts, max})
    let result = testResults[topKey]

    function resetHandler() {
        props.onReset()
    }

    return (
        <div className={"test-result-container about-test-container"}>
            <h3>Результат: {result?.title}</h3>

            <div>
                {result?.content}
            </div>

            <Button onClick={resetHandler} text={"Пройти тест еще раз"}/>
        </div>
    );
};

export default TestResult;