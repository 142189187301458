import React from "react";
import Mrgn from "../../layout/Mrgn";
import {Link} from "react-router-dom";
import {ARTICLES_ADMIN_PATH, LONGREADS_ADMIN_PATH, NEWS_ADMIN_PATH} from "../../service/const/paths";

function Panel() {
    return (
        <>
            <Mrgn/>
            <section className="max-w-[1200px] m-auto text-left">
                <div>
                    <div className="grid grid-cols-3 gap-8">
                        <Link to={LONGREADS_ADMIN_PATH}
                              className="bg-zinc-900 border border-zinc-600 rounded hover:bg-zinc-700 cursor-pointer transition-all p-4 text-center"
                        >
                            <h6 className="text-2xl">Блоги</h6>
                        </Link>

                        <Link to={NEWS_ADMIN_PATH}
                              className="bg-zinc-900 border border-zinc-600 rounded hover:bg-zinc-700 cursor-pointer transition-all p-4 text-center"
                        >
                            <h6 className="text-2xl">Новости</h6>
                        </Link>

                        <Link to={ARTICLES_ADMIN_PATH}
                              className="bg-zinc-900 border border-zinc-600 rounded hover:bg-zinc-700 cursor-pointer transition-all p-4 text-center"
                        >
                            <h6 className="text-2xl">Издания</h6>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Panel;
