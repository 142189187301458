import "../../../styles/home.css";
import HTwo from "../../../components/headers/HTwo";
import Accordion from "../../course/components/accordion/Accordion";
import {faq_vars} from "./utils";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useRef} from "react";


const Faq = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const ref = useRef(null)

    useEffect(() => {
        if (location.state?.ref == "faq" && ref?.current) {
            setTimeout(() => {
                // @ts-ignore
                window.scrollTo({top: ref.current.offsetTop, behavior: 'smooth'});
                navigate(location.pathname, {});
            }, 100)
        }
        // @ts-ignore
    }, [location.state?.ref, ref.current?.offsetTop])

    return (
        <section ref={ref}>
            <div className="longreads">
                <HTwo>FAQ</HTwo>
            </div>
            <div className={"courses-faq"}>
                {
                    faq_vars.map((el) =>
                        <Accordion
                            head={el.head}
                            headStyle={{fontSize: "1.4em"}}
                            content={el.content}/>
                    )
                }
            </div>
        </section>
    );
};

export default Faq;
