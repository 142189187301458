import React from 'react';

const SubscribeInfo = (props:any) => {
    const {subscribe} = props

    return (
        <div className={"p-2 md:max-w-[600px] m-auto mb-8 md:text-lg"} style={{textAlign: 'center'}}>
            {
                subscribe ?
                    <>
                        <p style={{fontWeight: 600, fontSize: 20, marginBottom: 4}}>
                            {subscribe?.subscribeName}
                        </p>
                        <p>
                            {subscribe?.subscribeDescription}
                        </p>
                    </>
                    : <p>Выберите подписку, которая наиболее подходит вашим потребностям и начните получать
                        удовольствие от качественного контента уже сегодня!</p>
            }
        </div>
    );
};

export default SubscribeInfo;
