import React, {useEffect, useState} from "react";
import useCourseInfo from "../../service/hooks/useCourseInfo"; //custom hook
import "../../styles/componentStyles/coursesPage/coursePlayer.css";
import {LONGREADS} from "../longreads/nonhuman_longreeds";
import {getContentBlocks, getFilesBlocks, getLongreads,} from "../../service/api/coursesApi";
import {AiFillFile} from "react-icons/ai";
import LongreadCard from "../../stories/longreads/LongreadCard";
import { API } from "../../service/const/api";

interface ICoursePlayerProps {
	courseURLId: string;
	purchasedBlocks: any[];
}

function extractNumbersFromLink(link: string) {
	const regex = /\d+/g;
	const match = link.match(regex);
	return match ? match.join("") : "";
}
function CoursePlayer({ courseURLId, purchasedBlocks }: ICoursePlayerProps) {
	const TOKEN = localStorage.getItem("token");
	const [allBlocks, setAllBlocks] = useState<any>(null);
	const [allBlocksTemp] = useCourseInfo(courseURLId);
	const [activeBlock, setActiveBlock] = useState<any>(null);
	const [currentBlockContent, setCurrentBlockContent] = useState<any>(null);
	const [currentLecture, setCurrentLecture] = useState<any>(0);
	const [currentBlockFiles, setCurrentBlockFiles] = useState<any>(null);
	const [currentLongreads, setCurrentLongreads] = useState<any>(null);

	//!need to check for courseInfo.P or undefined v eblo
	useEffect(() => {
		if (allBlocksTemp.p) {
			setAllBlocks(allBlocksTemp.p.b);
		}
	}, [allBlocksTemp]);
	const blockLoadCheck = allBlocks && purchasedBlocks; //* anti undefind v eblo

	//*onclick func
	const selectBlock = (BlockURLId: string | undefined) => {
		setActiveBlock(BlockURLId);
		setCurrentLecture(0);
		if (!currentBlockContent) {
			setCurrentBlockContent(null);
		}
		// console.log(currentBlockContent);
		// console.log(activeBlock); //string city-and-society
	};

	useEffect(() => {
		if (activeBlock) {
			const fetchBlockContent = async () => {
				const response = await getContentBlocks(
					TOKEN,
					activeBlock,
					courseURLId
				);
				if (response.data) {
					setCurrentBlockContent(response.data);
				} else {
					setCurrentBlockContent(null);
				}
			};
			fetchBlockContent();
		}
	}, [activeBlock]);

	useEffect(() => {
		if (activeBlock) {
			const fetchBlockFiles = async () => {
				const response = await getFilesBlocks(TOKEN, activeBlock, courseURLId);

				if (!Array.isArray(response.data)) {
					// data is not an array if error
					setCurrentBlockFiles(null);
					// setCurrentBlockContent(null);
					// setting content to null, otherwise onclick always shows same lecture idk
				} else {
					// console.log(response.data);
					setCurrentBlockFiles(response.data);
				}
			};
			fetchBlockFiles();
			// console.log(currentBlockFiles);
		}
	}, [activeBlock]);

	useEffect(() => {
		if (activeBlock) {
			const fetchLongreads = async () => {
				const response = await getLongreads(TOKEN, activeBlock, courseURLId);
				console.log(response?.data);
				if (!Array.isArray(response.data)) {
					// data is not an array if error
					setCurrentLongreads(null);
					// setCurrentBlockContent(null);
					// setting content to null, otherwise onclick always shows same lecture idk
				} else {
					// console.log(response.data);
					setCurrentLongreads(response.data);
				}
			};
			fetchLongreads();
			// console.log(currentBlockFiles);
		}
	}, [activeBlock]);
	//setting current lecture
	//* setting active block when user only opened the page
	function meow() {
		const purchasedEzCheckArr = purchasedBlocks.map((element: any) => {
			return element["blockId"].toString();
		});
		// console.log(purchasedEzCheckArr);
		const ezCheckAllBlocksArr = allBlocks.map((element: any) => {
			return element["blockId"].toString();
		});
		// console.log(ezCheckAllBlocksArr);
		const filtered = ezCheckAllBlocksArr.filter((element: any, index: any) => {
			if (element == purchasedEzCheckArr[index]) return element;
		});

		return filtered;
	}

	// useEffect(() => {
	// 	if (currentBlockContent) {
	// 	}
	// }, [currentBlockContent]);

	useEffect(() => {
		if (purchasedBlocks && allBlocks) {
			const [arrTogether] = meow(); //1
			allBlocks.forEach((element: any) => {
				if (element["blockId"] == arrTogether) {
					setActiveBlock(element["blockURLId"]);
				}
			});
		}
	}, [allBlocks]);

	const fixLinkForFile = (link: string) => {
		if (!link) return;
		const meow = link.slice(0, 5);
		if (meow === "https") {
			return link;
		} else {
			return `${API}/" + link`
		}
	};

	const switchBlock = (block: any) => {};

	return (
		<>
			<div className="course-player-main-container">
				<div className="blocks-container">
					{/* {allBlocks && allBlocks[0]["@BlockId"]} */}
					{blockLoadCheck &&
						allBlocks.map((element: any, index: any) => {
							const purchasedEzCheckArr = purchasedBlocks.map(
								(element: any) => {
									return element["blockId"].toString();
								}
							);
							// console.log("meow " + element["@BlockId"]);
							const idk = element["blockId"].toString();
							// console.log(purchasedEzCheckArr.includes(idk));
							if (purchasedEzCheckArr.includes(idk)) {
								return (
									<p
										onClick={() => selectBlock(element["blockURLId"])}
										className={
											element["blockURLId"] === activeBlock
												? "block-purchased active-block"
												: "block-purchased"
										}
										key={element["blockId"]}
									>
										{element["blockName"]}
									</p>
								);
							} else {
								return (
									<p className="block-not-purchased" key={element["blockId"]}>
										{element["blockName"]}
									</p>
								);
							}
						})}
				</div>
				<div className="content-container">
					{currentBlockContent ? (
						<iframe
							className="iframe-player"
							src={`${currentBlockContent[currentLecture]}`}
							width="5600"
							height="3150"
							// frameborder="none"
							allow="autoplay; fullscreen; picture-in-picture; encrypted-media;"
							// allowFullScreen="true"
						></iframe>
					) : (
						<div className="no-video-block">
							<p>Записи этого блока пока нет, она появится позже</p>
						</div>
					)}

					<div className="button-container">
						{currentBlockContent &&
							currentBlockContent.map((button: any, index: any) => {
								return (
									<div
										onClick={() => setCurrentLecture(index)}
										key={button}
										className={
											index === currentLecture
												? "switch-video-button active-video-button"
												: "switch-video-button"
										}
									>
										{index + 1}
									</div>
								);
							})}
					</div>
					{currentBlockFiles && (
						<div className="files-container">
							<h4>Дополнительные материалы к блоку</h4>
							<div className="files-row">
								{currentBlockFiles.map((element: any, index: any) => {
									return (
										<a
											target="_blank"
											href={fixLinkForFile(element.srcMaterialInfo)}
											className="file"
										>
											<AiFillFile color="#6565ee" className="file-icon" />
											{/* <p>{index + 1}</p> */}
											<p>{element.materialName}</p>
										</a>
									);
								})}
							</div>
						</div>
					)}
					{currentLongreads && (
						<div className="files-container">
							<h4>Лонгриды по теме</h4>
							<div className="longreads-container-course">
								{currentLongreads.map((element: any, index: any) => {
									console.log(element.contentUrlid);
									return (
										<>
											<LongreadCard
												to={`../../longreads/${extractNumbersFromLink(
													element.contentUrlid
												)}`}
												name={element.contentTitle}
												img={
													//@ts-ignore

													LONGREADS[
														extractNumbersFromLink(element.contentUrlid)
													].background_image
												}
												descr={
													//@ts-ignore
													LONGREADS[
														extractNumbersFromLink(element.contentUrlid)
													].article_descr
												}
											/>
										</>
									);
								})}
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default CoursePlayer;
