import axios from "axios";
import {SUBSCRIBE_API} from "../const/api";
import {getQueryVariable} from "../functions";

const subscribeApi = axios.create({
    baseURL: SUBSCRIBE_API,
    headers: {
        "Content-Type": "application/json",
    },
});

export const checkIsSubscribe = async (): Promise<any> => {
    let token = localStorage.getItem("token");
    return subscribeApi.get(`/IsSubscribe${getQueryVariable({token:token})}`)
        .then((response) => response.data.result)
        .catch(() => {
            return false
        })
};

export const getAllCoursesAllowSubscribe = async (): Promise<any> => {
    return subscribeApi.get(`/GetAllCourses`)
        .then((response) => response.data.result)
        .catch(() => {
            return null
        })
};

export const getAllSubscribes = async (): Promise<any> => {
    return subscribeApi.get(`/GetAllSubscribes`)
        .then((response) => response.data)
        .catch(() => {
            return null
        })
};

export const buyTinkoffSubscribe = async (
    token: string | null,
    subscribeid: number,
    blockURLId?: string
) => {
    let link = `/GenerateSubscibePurchaseLink?token=${token}&subscribeid=${subscribeid}`;
    if (blockURLId) link = link + `&${blockURLId}`;
    return subscribeApi.get<string>(link);
};
