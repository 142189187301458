import React, {useContext, useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {IRegisterNewUser} from "../service/types/interfaces";
import {registerNewUser} from "../service/api/authApi";
import {Link, useNavigate} from "react-router-dom";
import {HOME_PATH, SUCCESS_REGISTER_PATH} from "../service/const/paths";
import AuthContext from "../service/context/AuthProvider";

const FormRegister = (props: any) => {
    const navigate = useNavigate()
    const [isAllowed, setIsAllowed] = useState(true);
    const [isConfirmedPolicy, setIsConfirmedPolicy] = useState(true);
    const [error, setError] = useState("");

    const {setAuth}: any = useContext(AuthContext);

    const doRegistration = async (user: IRegisterNewUser) => {
        const response = await registerNewUser({
            ...{
                name: `${user.lastname} ${user.firstname}`,
                email: user.email
            },
            NewsletterSubscription: isAllowed,
            referFrom: localStorage.getItem("refer") || null,
        });
        if (response.status >= 400) {
            setError(response.data);
        } else {
            const {data} = response
            localStorage.setItem(`token`, `${data.token}`);
            localStorage.setItem(`refreshToken`, `${data.refreshToken}`);
            setAuth({
                token: data.token,
                refreshToken: data.refreshToken,
            });
            if (props.buyFunc) props.buyFunc()
            else navigate(SUCCESS_REGISTER_PATH);
        }
    };

    const onSubmit: SubmitHandler<any> = async (data) => {
        if (!isConfirmedPolicy) {
            alert("Необходимо принять Публичную оферту и политику Конфиденциальности")
            return
        }
        await doRegistration(data);
    }

    const {
        register,
        handleSubmit,
        formState: {errors},
        watch,
    } = useForm();

    return (
        <div className="form-container form-login-bg">
            <h2>Регистрация</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group">
                    <div className="label-group">
                        <label htmlFor="name-field">Имя</label>
                        <div className="required-error">
                            {errors?.firstname?.type == "required" && (
                                <span className="field-error">(обязательное поле!)</span>
                            )}
                        </div>
                    </div>
                    <input
                        id="name-field"
                        type="text"
                        {...register("firstname", {
                            required: true,
                            minLength: 4,
                            maxLength: 50,
                        })}
                    />
                    <div className="input-error">
                        {errors?.firstname?.type == "minLength" && (
                            <p>Слишком короткое имя</p>
                        )}
                        {errors?.firstname?.type == "maxLength" && (
                            <p>Слишком длинное имя</p>
                        )}
                    </div>
                </div>
                <div className="input-group">
                    <div className="label-group">
                        <label htmlFor="lastname-field">Фамилия</label>
                        <div className="required-error">
                            {errors?.lastname?.type == "required" && (
                                <span className="field-error">(обязательное поле!)</span>
                            )}
                        </div>
                    </div>

                    <input
                        id="lastname-field"
                        type="text"
                        {...register("lastname", {
                            required: true,
                            minLength: 4,
                            maxLength: 50,
                        })}
                    />
                    <div className="input-error">
                        {errors?.lastname?.type == "minLength" && (
                            <p>Слишком короткая фамилия</p>
                        )}
                        {errors?.lastname?.type == "maxLength" && (
                            <p>Слишком длинная фамилия</p>
                        )}
                    </div>
                </div>
                <div className="input-group">

                    <div className="label-group">
                        <label htmlFor="email-field">E-mail</label>
                        <div className="required-error">
                            {errors?.email?.type == "required" && (
                                <span className="field-error">(обязательное поле!)</span>
                            )}
                        </div>
                    </div>

                    <input
                        id="email-field"
                        {...register("email", {
                            required: true,
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "С почтовым адресом что-то не так",
                            },
                        })}
                    />

                    <div className="input-error">
                        {error && (<p style={{fontSize: 14, marginTop: 8}}>{error.toString()}</p>)}
                        {errors?.email?.message && (
                            <p>{errors.email?.message.toString()}</p>
                        )}
                    </div>
                </div>
                <label htmlFor={"isAllowed"} className="checkbox-container"
                       style={{display: "block", cursor: "pointer"}}>
                    <input checked={isAllowed}
                           onChange={() => setIsAllowed((prev) => !prev)}
                           type="checkbox"
                           id={"isAllowed"}
                    />
                    <label htmlFor={"isAllowed"}>Разрешить рассылку</label>
                </label>
                <label htmlFor={"isConfirmedPolicy"} className="checkbox-container"
                       style={{marginTop: 10, display: "block", cursor: "pointer"}}>
                    <input checked={isConfirmedPolicy}
                           onChange={() => setIsConfirmedPolicy((prev) => !prev)}
                           type="checkbox"
                           id={"isConfirmedPolicy"}
                    />
                    <label htmlFor={"isConfirmedPolicy"}>Принимаю <Link to={"/oferta"}
                                                                        style={{textDecoration: "underline"}}>Публичную
                        оферту</Link> и <Link to={"/privacy_policy"} style={{textDecoration: "underline"}}>Политику
                        конфиденциальности</Link></label>
                </label>
                <button className="button">
                    <input type="submit"/>
                    <p>{props.buyFunc ? `к оплате ${props.courseCurrentPrice} р.` : 'регистрация'}</p>
                </button>
                {localStorage.getItem("refer") && (
                    <div className="is-refer">
                        <p>
                            Если реферальная ссылка действительна, она будет применена
                        </p>
                    </div>
                )}
            </form>
        </div>
    );
};

export default FormRegister;
