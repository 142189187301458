import React, {useEffect, useState} from "react";
import Mrgn from "../../layout/Mrgn";
import {validateTinkoffSubscribe} from "../../service/api/buyApi";
import {useSearchParams} from "react-router-dom";

interface IGoodError {
    error?: string;
}

type PossiblePurchaseValidationResponseContent = {
    status: number;
    data: string | IGoodError;
};

function ValidateSubscribePurchase() {
    const [searchParams] = useSearchParams();
    const [paymentStatus, setPaymentStatus] = useState("");
    const userId = searchParams.get("userId")!.toString();
    const orderId = searchParams.get("orderId")!.toString();

    useEffect(() => {
        const validatePurchase = async () => {
            if (userId) {
                const response: PossiblePurchaseValidationResponseContent =
                    await validateTinkoffSubscribe(userId, orderId);
                if (!response) {
                    setPaymentStatus("Что-то пошло не так");
                }
                if (response.status === 200 && typeof response.data == "string") {
                    setPaymentStatus(response.data);
                }
                if (response.status === 666) {
                    setPaymentStatus("Все хорошо, подписка куплена");
                } else {
                    // @ts-ignore
                    setPaymentStatus(response.data.error);
                }
            }
        };
        validatePurchase();
    }, []);

    return (
        <>
            <Mrgn />
            <div
                style={{
                    display: "flex",
                    marginTop: "5rem",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                className="purchase-validation-container"
            >
                <div>{paymentStatus}</div>
            </div>
        </>
    );
}

export default ValidateSubscribePurchase;
