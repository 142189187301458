import React from "react";
import {Link} from "react-router-dom";

type TProps = {
	name: string;
	to: string;
	img: any;
	descr?: string;
	type?: string;
};

function LongreadCard({ name, to = "#", img, descr, type }: TProps) {
	return (
		<Link
			to={to}
			className="w-[300px] md:w-[360px] inline-block max-w-[360px] "
		>
			<div
				className="relative w-[300px] md:w-[360px] h-[200px] rounded-[8px] bg-center bg-cover"
				style={{ backgroundImage: `url(${img})` , width: "100%"}}
			>
				{type && (
					<div className="absolute mt-2 ml-4 bg-black bg-opacity-50 rounded">
						<p className=" py-1 px-2 text-zinc-400">{type}</p>
					</div>
				)}
			</div>
			<h3 className="max-w-[99%] ml-[3px] mt-4 text-[20px] ">{name}</h3>
			<p className="mt-2 text-zinc-400 ">{descr}</p>
		</Link>
	);
}

export default LongreadCard;
