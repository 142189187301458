export const  faq_vars = [
    {
        head: "Каким образом происходит оплата курса?",
        content: "На нашем сайте оплата происходит автоматически через платежную систему банка Тинькофф. Мы принимаем оплаты с карт MasterCard, Visa и Мир. Сразу после оплаты вам становится доступен курс в полном объеме."
    },
    {
        head: "Где появится курс после покупки?",
        content: "Все приобретенные курсы будут находиться в вашем личном кабинете, перейти в который вы можете, нажав на иконку в правом верхнем углу сайта."
    },
    {
        head: "Возможно ли оплатить отдельные части курса, если я хочу получить материал только на определенную тему?",
        content: "Нет, такое на нашей платформе не предусмотрено. На данный момент вы можете приобрести только весь курс целиком."
    },
    {
        head: "Будут ли мне доступны все последующие обновления курса?",
        content: "Мы регулярно обновляем все наши курсы и каждое такое обновление автоматически актуализируется в вашем личном кабинете. Приобретая однажды какой-то курс, вы автоматически получаете подписку на все его дальнейшие обновления и расширения."
    },
    {
        head: "Могу ли я пообщаться с другими участниками курсов или с авторами курсов?",
        content: "Да, в рамках большинства курсов предусматривается доступ к закрытым телеграм-каналам, в которых происходит общение между участниками курса и его авторами. "
    },
    {
        head: "Если у меня возникнут какие-то вопросы, то куда я могу обращаться?",
        content: "Вы можете написать ваш вопрос на почту info@nysacademy.ru. Ответ приходит либо в тот же день либо в течение 3 дней."
    }
]
