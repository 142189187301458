import "../../../styles/componentStyles/coursesPage/course.css";
import {fixImgSrc, getCoursesPage, getPurchasedBlocks} from "../../../service/api/coursesApi";
import React, {useContext, useEffect, useRef, useState} from "react";
import useCourseInfo from "../../../service/hooks/useCourseInfo";
import CoursePlayer from "../CoursePlayer";
import AuthContext from "../../../service/context/AuthProvider";
import {Link} from "react-router-dom";
import BuyCourse from "../BuyCourse";
import ChatLink from "../../../etc/ChatLink";
import useSetMeta from "../../../service/hooks/useSetMeta";
import ReviewsCarousel from "../../../widgets/reviews/ReviewsCarousel";
import ChatScreen from "../components/ChatScreen";
import {buyTinkoff} from "../../../service/api/buyApi";
import FormRegister from "../../../stories/FormRegister";
import review_1 from "./img/review_1.jpg"
import review_2 from "./img/review_2.jpg"
import review_3 from "./img/review_3.jpg"
import review_4 from "./img/review_4.jpg"
import review_5 from "./img/review_5.jpg"

function Gorodvovne() {
    const ref = useRef<HTMLDivElement>(null)
    const {auth}: any = useContext(AuthContext);

    useSetMeta({
        title: "Онлайн-курс “Город вовне. История исследования города”",
        description: "Изучите феномен города со всех сторон. Полное погружение в социологию города, урбанистику и историю!"
    })

    const reviews = [review_1, review_2, review_3, review_4, review_5]

    const buyCourse = async () => {
        if (!localStorage.getItem("token")) {
            if (ref?.current) {
                ref.current?.scrollIntoView({behavior: "smooth", block: "center"});
            }
        } else {
            const response = await buyTinkoff(
                localStorage.getItem("token"),
                COURSEURL
            );
            if (response?.data) {
                if (response.data) {
                    window.location.replace(response.data);
                }
            }
        }
    };

    const COURSEURL = "city-outside"; //* CHANGE THIS WHEN COPY PASTING
    const LATIN = [
        "I",
        "II",
        "III",
        "IV",
        "V",
        "VI",
        "VII",
        "VIII",
        "IX",
        "X",
        "XI",
        "XII",
        "XIII",
        "XIV",
        "XV",
    ];
    const [courses, setCourses] = useState<ICoursesPage | []>([]);
    const [course, setCourse] = useState<any>({});

    interface ICoursesPage {
        courseId: number;
        courseName: string;
        courseUrlid: string;
        currentPrice: any;
        description: string;
        oldPrice: any;
        srcPicturePage: string;
    }

    useEffect(() => {
        const fetchCourses = async () => {
            const responseCourses = await getCoursesPage();
            // console.log(responseCourses);
            if (responseCourses.status === 200) {
                setCourses(responseCourses.data);
                setCourse(
                    await responseCourses.data.filter((course: ICoursesPage) => {
                        return course.courseUrlid === COURSEURL;
                    })[0]
                );
            }
        };
        fetchCourses();
    }, []);

    // console.log(course);

    //* getting button names and info (not content)
    const [courseInfo] = useCourseInfo(COURSEURL);
    // console.log(courseInfo);

    //*purchased button

    const [purchasedBlocks, setPurchasedBlocks] = useState<any>(null);

    useEffect(() => {
        const fetchPurchasedBlocks = async () => {
            if (localStorage.getItem("token")) {
                //!only if autherized
                const response = await getPurchasedBlocks(
                    localStorage.getItem("token"),
                    COURSEURL
                );
                if (response.data.error === "Курс не был куплен") {
                    setPurchasedBlocks(null);
                    return;
                }
                if (response.data) {
                    setPurchasedBlocks(response.data);
                    // console.log(response.data);
                }
            } else return;
        };
        fetchPurchasedBlocks();
    }, []);

    let showPlayer = false;
    if (auth && localStorage.getItem("token") && purchasedBlocks) {
        showPlayer = true;
    }

    function CourseLanding() {
        return (
            <>
                <div className="course-description">
                    <p className="descr-string">Описание курса</p>
                    <p className="descr-text-main-1">
                        В курсе "Город вовне" рассматриваются различные подходы к
                        определению и исследованию города, затрагивается тема эволюции
                        городов и делается попытка составить портрет «города будущего». В
                        рамках курса мы обращаемся к различным дисциплинам, которые
                        изучают город – к урбанистике, социологии города, истории и
                        географии.
                    </p>

                    <div className="descr-mid-container">
                        <div className="descr-how-many">
                            <h2>6</h2>
                            <p>практических лекций</p>
                        </div>

                        <div className="descr-who-container">
                            <div className="descr-who">
                                <img src="../faces/nikita.jpg" alt=""/>

                                <div className="container-who">
                                    <p className="descr-who-reads">курс читает</p>
                                    <h2 className="descr-who-name">НИКИТА ЗВЕРЕВ</h2>
                                    <div className="descr-who-job">философ, основатель академии “Нус”</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className="descr-text-main-2">
                        Мы пытаемся рассмотреть явление города в наиболее полном объеме,
                        окунуться в историю городов и понять что же города представляют из
                        себя прямо сейчас.
                    </p>
                    <p className="descr-what-you-learn-p">Что вы узнаете на курсе?</p>
                    <div className="descr-what-you-learn-container">
                        <div className="descr-what-you-learn-text">
                            <p>
                                какие методы используется в современных городских
                                исследованиях?
                            </p>
                        </div>
                        <div className="descr-what-you-learn-text">
                            <p>
                                какие бывают технологии искусственного интеллекта и как они
                                применяются в городе?
                            </p>
                        </div>
                        <div className="descr-what-you-learn-text">
                            <p>
                                что такое "(не)живая жизнь" и почему город тоже является живым
                                объект;
                            </p>
                        </div>
                        <div className="descr-what-you-learn-text">
                            <p>
                                как будет выглядеть город будущего и начнется ли эра
                                киберпанка?
                            </p>
                        </div>
                        <div className="descr-what-you-learn-text">
                            <p>в чем заключается мистицизм города?</p>
                        </div>
                        <div className="descr-what-you-learn-text">
                            <p>
                                историю городского развития от древних мифов до наших дней;
                            </p>
                        </div>
                    </div>
                </div>

                <ChatScreen type={"socio"}/>

                <div className="course-program">
                    <h2>Программа курса</h2>
                    <div className="course-program-blocks">
                        {courseInfo.p ? (
                            courseInfo.p.b.map((element: any, index: any) => {
                                return (
                                    <div key={element["blockId"]}>
                                        <h3 className="block-name">
                                            {LATIN[index]}&nbsp;&nbsp;&nbsp;
                                            {element["blockName"]}
                                        </h3>
                                        <div className="subblocks">
                                            {element.i.map((elementInner: any) => {
                                                return (
                                                    <p key={elementInner["blockInfoBlockInfoId"]}>
                                                        — {elementInner["blockInfoBlockInfoName"]}
                                                    </p>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div>Загрузка...</div>
                        )}
                    </div>
                </div>
                <div className="buy-course-end">
                    <BuyCourse COURSEURL={COURSEURL} buyCourse={buyCourse}/>
                </div>


                <ReviewsCarousel maxCount={5} reviews={reviews}/>
            </>
        )
    }

    return (
        <>
            {/* <Mrgn /> */}
            <div className="course-nav">
                <Link to="/">
                    <img width="150px" height="85px" src="../../logo.png" alt=""/>
                </Link>
            </div>
            {course.courseId ? (
                <div className="course-container">
                    <div
                        className="course-hero"
                        style={{
                            backgroundImage: `url(${fixImgSrc(
                                "files/course-pictures/city-outside-3.png"
                            )})`,
                        }}
                    >
                        <div className="course-hero-info">
                            <p className="online-course-text">Онлайн курс</p>

                            <h1>{course.courseName.toUpperCase()}</h1>
                            <BuyCourse COURSEURL={COURSEURL} buyCourse={buyCourse}/>
                        </div>
                    </div>
                    {
                        !localStorage.getItem("token") &&
                        <div style={{marginTop: 32}} ref={ref}>
                            <div className="p-2 text-center my-4 md:max-w-[1000px] m-auto">
                                Для приобритения курса зарегестрируйтесь на сайте
                            </div>
                            <FormRegister discount={courseInfo?.p?.courseOldPrice}
                                          courseOldPrice={courseInfo?.p?.courseOldPrice}
                                          courseCurrentPrice={courseInfo?.p?.courseCurrentPrice}
                                          buyFunc={buyCourse}/>
                        </div>
                    }
                    {showPlayer && (
                        <>
                            <CoursePlayer
                                purchasedBlocks={purchasedBlocks}
                                courseURLId={COURSEURL}
                            />
                            <ChatLink
                                chatName="Чат “Социологический (non)human”"
                                chatLink="https://t.me/+9rk2iMgUH2tjNDAy"
                            />
                        </>
                    )}

                    {!showPlayer && <CourseLanding/>}
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
}

export default Gorodvovne;
