import "../../styles/componentStyles/coursesPage/course.css";
import {fixImgSrc, getCoursesPage, getPurchasedBlocks} from "../../service/api/coursesApi";
import {useContext, useEffect, useRef, useState} from "react";
import useCourseInfo from "../../service/hooks/useCourseInfo"; //custom hook
import CoursePlayer from "./CoursePlayer";
import AuthContext from "../../service/context/AuthProvider"; //if auth token
import BuyCourse from "./BuyCourse";
import {Link} from "react-router-dom";
import {buyTinkoff} from "../../service/api/buyApi";

function NetworkTheory() {
	const ref = useRef<HTMLDivElement>(null)
	const { auth }: any = useContext(AuthContext);

	const buyCourse = async () => {
		if (!localStorage.getItem("token")) {
			if (ref?.current) {
				ref.current?.scrollIntoView({behavior: "smooth", block: "center"});
			}
		} else {
			const response = await buyTinkoff(
				localStorage.getItem("token"),
				COURSEURL
			);
			if (response?.data) {
				if (response.data) {
					window.location.replace(response.data);
				}
			}
		}
	};

	// console.log(showPlayer);
	const COURSEURL = "actor-network-theory"; //* CHANGE THIS WHEN COPY PASTING
	const LATIN = [
		"I",
		"II",
		"III",
		"IV",
		"V",
		"VI",
		"VII",
		"VIII",
		"IX",
		"X",
		"XI",
		"XII",
		"XIII",
		"XIV",
		"XV",
	];
	const [courses, setCourses] = useState<ICoursesPage | []>([]);
	const [course, setCourse] = useState<any>({});
	interface ICoursesPage {
		courseId: number;
		courseName: string;
		courseUrlid: string;
		currentPrice: any;
		description: string;
		oldPrice: any;
		srcPicturePage: string;
	}
	useEffect(() => {
		const fetchCourses = async () => {
			const responseCourses = await getCoursesPage();
			// console.log(responseCourses);
			if (responseCourses.status === 200) {
				setCourses(responseCourses.data);
				setCourse(
					await responseCourses.data.filter((course: ICoursesPage) => {
						return course.courseUrlid === COURSEURL;
					})[0]
				);
			}
		};
		fetchCourses();
	}, []);

	// console.log(course);

	//* getting button names and info (not content)
	const [courseInfo] = useCourseInfo(COURSEURL);
	// console.log(courseInfo);

	//*purchased button

	const [purchasedBlocks, setPurchasedBlocks] = useState<any>(null);

	useEffect(() => {
		const fetchPurchasedBlocks = async () => {
			if (localStorage.getItem("token")) {
				//!only if autherized
				const response = await getPurchasedBlocks(
					localStorage.getItem("token"),
					COURSEURL
				);
				if (response.data.error === "Курс не был куплен") {
					setPurchasedBlocks(null);
					return;
				}
				if (response.data) {
					setPurchasedBlocks(response.data);
					// console.log(response.data);
				}
			} else return;
		};
		try {
			fetchPurchasedBlocks();
		} catch {
			console.log("oops");
		}
	}, []);

	let showPlayer = false;
	if (auth && localStorage.getItem("token") && purchasedBlocks) {
		showPlayer = true;
	}
	return (
		<>
			{/* <Mrgn /> */}
			<div className="course-nav">
				<Link to="/">
					<img width="150px" height="85px" src="../../logo.png" alt="" />
				</Link>
			</div>
			{course.courseId ? (
				<div className="course-container">
					<div
						className="course-hero"
						style={{
							backgroundImage: `url(${fixImgSrc(
								course.srcPictureAdditional1
							)})`,
						}}
					>
						<div className="course-hero-info">
							<h1>{course.courseName.toUpperCase()}</h1>
							<BuyCourse COURSEURL={COURSEURL} buyCourse={buyCourse}/>
						</div>
					</div>
					{/* <iframe
						src="https://kinescope.io/embed/202165183"
						width="560"
						height="315"
						// frameborder="2"
						allow="autoplay; fullscreen; picture-in-picture; encrypted-media;"
						// allowFullScreen="true"
					></iframe> */}
					{/* //!player is here */}
					{/* //* will check auth and render only if course bought and logged index */}
					{showPlayer && (
						<CoursePlayer
							purchasedBlocks={purchasedBlocks}
							courseURLId={COURSEURL}
						/>
					)}
					<div className="course-description">
						<p className="descr-string">Описание курса</p>
						<p className="descr-text-main-1">
							Акторно-сетевая теория (АСТ) — это относительно современная
							концепция, совершившая революцию в социальных науках, переосмыслив
							понятие социального и обозначив поворот к материальному. В немалой
							степени именно она вдохновила большую часть направлений в
							современной философии. АСТ — это отличный инструмент для
							прикладных социологических исследований, которые будут
							востребованы ещё долгое время.
						</p>
					</div>
					<div className="network-theory">
						<img src="../networkTheoryPics/2.png" alt="" />
						<p className="white-bg">
							В этом онлайн-курсе хронологически охвачены все этапы развития
							теории. От предшествующих философов, до научных войн и современных
							последователей.{" "}
						</p>
						<img src="../networkTheoryPics/1.png" alt="" />
						<p className="descr-text-main-2">
							Мы рассмотрели все наиболее важные работы Латура, а также статьи и
							книги Каллона, Ло, Мол и других. Все книги и статьи собраны для
							слушателей нашего курса
						</p>
						<p className="descr-text-main-2">
							Также для понимания контр интуитивной логики этой теории мы
							предлагаем вам схемы теоретических влияний, процессов так
							называемого «перевода», а также несколько лонгридов проекта
							(non)human и многое другое.
						</p>
						<h2>Чем этот курс будет полезен для вас?</h2>
						<ul>
							<li>
								АСТ - это введение в современную философию и социальные науки
							</li>
							<li>Применение в разных областях помимо социологии</li>
							<li>От менеджмента до географии</li>
							<li>Эмпирическая ориентация теории</li>
						</ul>
					</div>
					<div className="present">
						<div>
							<h2>подарок</h2>
							<p>
								При покупке курса дарим к подкастам "Политики природы" и полной
								версии "Раннего Каллона"
							</p>
						</div>
						<div>
							<img src="../gift_icon.png" alt="" />
						</div>
					</div>
					<div className="course-program">
						<div className="course-program-blocks"></div>
					</div>

					<div className="buy-course-end">
						<BuyCourse COURSEURL={COURSEURL} buyCourse={buyCourse}/>
					</div>
				</div>
			) : (
				<div></div>
			)}
		</>
	);
}

export default NetworkTheory;
