import React, {useEffect} from "react";
import Mrgn from "../../layout/Mrgn";
import {useNavigate, useSearchParams} from "react-router-dom";

function Refer() {
	const [params] = useSearchParams();
	const id = params.get("From");
	const navigate = useNavigate();

	useEffect(() => {
		if (id) {
			localStorage.setItem("refer", id.toString());
			navigate("../Register");
		}
	}, [id]);

	return (
		<>
			<Mrgn />
			<main className="unsub-main">
				<h1>Перенаправление...</h1>
			</main>
		</>
	);
}

export default Refer;
