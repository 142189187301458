import React, {useState} from 'react';
import Mrgn from "../../layout/Mrgn";
import PageHeader from "../../layout/PageHeader";
import "../../styles/componentStyles/podcastsLectures/podcastsLectures.css";
import {ANSWERS_KEY, COUNTS_KEY, NEED_RETURN_TO_TEST_KEY, questions, results} from "./questions";
import Question from "./Question";
import Page from "../../layout/Page";
import "./style.css"
import TestResult from "./TestResult";
import Button from "../../components/button/Button";
import {useNavigate} from "react-router-dom";
import useLocalStorage from "./useLocalStorage";
import useSetMeta from "../../service/hooks/useSetMeta";

const TestPage = () => {
    const [getLsAnswers, setLsAnswers, clearAnswers] = useLocalStorage(ANSWERS_KEY, {})
    const [getLsCounts, setLsCounts, clearCounts] = useLocalStorage(COUNTS_KEY, {...results})

    const navigate = useNavigate()
    const [answers, setAnswers] = useState(getLsAnswers())
    const [counts, setCounts] = useState(getLsCounts())

    //console.log(answers)

    const checkedAnswersCount = Object.keys(answers)?.length
    const canShowRes = checkedAnswersCount === questions?.length
    const [isResultPage, setIsResultPage] = useState(false)

    function onReset() {
        clearAnswers()
        setAnswers({})

        clearCounts()
        setCounts({...results})

        setIsResultPage(false)
    }

    function toResult() {
        if (localStorage.getItem("token")) {
            setIsResultPage(true)
        }
        else {
            alert("Для получения результата необходимо авторизоваться")
            localStorage.setItem(NEED_RETURN_TO_TEST_KEY, "true")
            navigate("/login")
        }
    }

    useSetMeta({
        title: "Определи свое призвание вместе с (non)human”",
        description: "Пройдите бесплатный профориентационный тест, состоящий из 25 вопросов, который поможет каждому определить свое призвание"
    })

    return (
        <>
            <Mrgn />
            <div className="p-and-l-container">
                <Page style={{maxWidth: 800}}>

                    <PageHeader style={{paddingBottom: 0}}>Тест на проф<wbr/>ориентацию</PageHeader>

                    {isResultPage?
                        <TestResult answers={answers} counts={counts} onReset={onReset}/>:
                        <>
                            <div className={"about-test-container"}>
                                <p>
                                    Предлагаем вам пройти особый профориентационный тест, состоящий из 25 вопросов, по итогу которого вы определите свое предназначение.
                                </p>
                                <p>
                                    Помимо описания результатов, вы также получите уникальный совет от лица команды (non)human по прохождению вашего профессионального пути.
                                </p>
                                <p>
                                    Местами вам может показаться, что вопросы сформулированы недостаточно точно. Не размышляйте над ними - отвечайте то, что первое пришло в голову.
                                </p>
                            </div>

                            <div className={"flex flex-col"} style={{gap: 30}}>
                                {questions.map((el, index)=>
                                    <Question key={el.question}
                                              item={el}
                                              index={index}

                                              answers={answers}
                                              setAnswers={setAnswers}
                                              setCounts={setCounts}
                                              counts={counts}
                                    />
                                )}
                            </div>

                            {!!checkedAnswersCount &&
                                <div className={"float-to-result-save-space"}>
                                    <div className={"float-to-result"}>
                                        <div>Вопросы: {checkedAnswersCount}/{questions.length}</div>

                                        <div style={canShowRes? {}: {pointerEvents: "none", opacity: "0.5"}}>
                                            <Button onClick={toResult} text={"Показать результат"}/>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </Page>
            </div>
        </>
    );
};

export default TestPage;
