import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {confirmEmail} from "../../service/api/authApi";

import Mrgn from "../../layout/Mrgn";

function ConfirmEmail() {
    const navigate = useNavigate();
    const [result, setResult] = useState<any>("nasfn");
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        const code = searchParams.get("code");
        const userId = searchParams.get("userId");
        const emailObj = {
            code,
            userId,
        };
        if ((userId && code) != null) {
            const res = confirmEmail(emailObj).then((result) => setResult(result));
        }
        setTimeout(() => {
            navigate("/login");
        }, 300);
    }, []);
    return (
        <>
            <Mrgn/>
            <div>meow confirm your email Nya :3</div>
            <div>{result?.toString()}</div>
        </>
    );
}

export default ConfirmEmail;
