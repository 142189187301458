import React, {useRef, useState} from "react";
import Mrgn from "../../layout/Mrgn";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import axios from "axios";
import LongreadCard from "../../stories/longreads/LongreadCard";
import {AiFillDelete} from "react-icons/ai";
import {BsChevronDoubleDown} from "react-icons/bs";
import {useNavigate, useSearchParams} from "react-router-dom";
import {API} from "../../service/const/api";
import {getFormLabels} from "./utils";
import {ARTICLES_PATH, BLOGS_PATH, CREATE_LONGREADS_ADMIN_PATH, NEWS_PATH} from "../../service/const/paths";

function CreateLongread() {
    const [searchParams] = useSearchParams();
    const type = Number(searchParams.get('type') ?? 0)

    const [inputed, setInputed] = useState(``);
    const [instructionOpened, setInstructionOpened] = useState(false);

    const [title, setTitle] = useState("");
    const [URL, setURL] = useState("");
    const [description, setDescription] = useState("");

    const [HTMLTitle, setHTMLTitle] = useState("");
    const [HTMLDescription, setHTMLDescription] = useState("");

    const [bgImage, setBgImage] = useState<any>(null);
    const [bgImagePreview, setBgImagePreview] = useState<any>(
        "https://cdn.crispedge.com/43464b.png"
    );

    const [materials, setMaterials] = useState<string[]>([]);
    const [material, setMaterial] = useState<string>("");

    const [reqError, setReqError] = useState(false);

    const [isSuccess, setIsSuccess] = useState(false);
    const navigate = useNavigate();

    const getPath = () => {
        const temp: any = {
            0: NEWS_PATH,
            1: BLOGS_PATH,
            2: ARTICLES_PATH
        }

        return temp[type]
    }

    const disabled = !title || !description || !URL || !HTMLTitle || !HTMLDescription || !inputed || !bgImage

    const sendData = async () => {
        const formData = new FormData();
        formData.append("Token", localStorage.getItem("token")!);
        formData.append("Type", type.toString());
        formData.append("Title", title);
        formData.append("Description", description);
        formData.append("URL", URL);
        formData.append("MarkdownString", inputed);
        formData.append("LongreadBgImage", bgImage);
        formData.append("HTMLTitle", HTMLTitle);
        formData.append("HTMLDescription", HTMLDescription);
        await axios.post(`${API}/api/Admin/LongReadFurther`, formData).then(sendMaterials).catch((e) => console.log(e))
    };

    const sendMaterials = async () => {
        try {
            if (type !== 0) {
                const response = await axios.post(
                    `${API}/api/Admin/setRecommendationsFurther`,
                    {
                        Token: localStorage.getItem("token"),
                        URL: URL,
                        Type: type,
                        Recommendations: materials,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (response?.status === 200) {
                    setIsSuccess(true);
                    setTimeout(() => {
                        navigate(getPath());
                    }, 1000);
                }
            } else {
                setIsSuccess(true);
                setTimeout(() => {
                    navigate(getPath());
                }, 1000)
            }
        } catch (err) {
            setReqError(true);
        }
    };

    const handleBgImageChange = (event: any) => {
        const file = event.target.files[0];

        if (file) {
            setBgImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setBgImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setBgImage(null);
            setBgImagePreview(null);
        }
    };

    const textareaRef = useRef<any>(null);

    const handleTextareaChange = (event: any) => {
        setInputed(event.target.value);
    };

    const handleTextareaKeyPress = (event: any) => {
        const textarea = textareaRef.current;
        const startPos = textarea.selectionStart;
        const endPos = textarea.selectionEnd;
        if (event.key === "Enter") {
            event.preventDefault();

            const newText =
                inputed.substring(0, startPos) +
                "<br>\n" +
                inputed.substring(endPos, inputed.length);

            setInputed(newText);
            textarea.focus();
            textarea.setSelectionRange(startPos + 4, startPos + 4);
        }
        if ((event.key === "i" || event.key === "ш") && event.ctrlKey) {
            event.preventDefault();

            const newText =
                inputed.substring(0, startPos) +
                `![field](https://i.imgur.com/MrGY5EL.jpeg "green field")<span class=image-description>Описание под картинкой</span>` +
                inputed.substring(endPos, inputed.length);

            setInputed(newText);
            textarea.focus();
            textarea.setSelectionRange(startPos + 4, startPos + 4);
        }
    };

    if (isSuccess) {
        return (
            <>
                <Mrgn/>
                <h1 className="text-3xl">Лонгрид загружен!</h1>
                <p className="text-center">Перенаправление...</p>
            </>
        );
    }

    return (
        <>
            <Mrgn/>
            <section className="ml-[3%] xl:ml-[10%] m-auto">
                <h3 className="text-xl mt-16 mb-8 md:text-4xl ">
                    {getFormLabels(type).title}
                </h3>
                <form>
                    <p>{`Название ${getFormLabels(type).name}*`}</p>
                    <input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="default-input w-[700px]"
                        type="text"
                    />

                    <p className="mt-2">{`Ссылка ${getFormLabels(type).name}(URL)*`}</p>
                    <input
                        placeholder="Пример: short-longread-name"
                        value={URL}
                        onChange={(e) => setURL(e.target.value)}
                        className="default-input w-[700px]"
                        type="text"
                    />

                    <p className="mt-4">Описание*</p>
                    <input
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="default-input w-[700px]"
                        type="text"
                    />

                    <div className=" mt-2">
                        <p>HTML Title*</p>
                        <input value={HTMLTitle}
                               onChange={(e) => setHTMLTitle(e.target.value)}
                               className="default-input w-[700px]"
                               type="text"
                        />
                        <p className="mt-2">HTML Описание*</p>
                        <input value={HTMLDescription}
                               onChange={(e) => setHTMLDescription(e.target.value)}
                               className="default-input w-[700px]"
                               type="text"
                        />
                    </div>


                    <p className="mt-4 text-lg">Обложка*</p>
                    <input type="file" onChange={handleBgImageChange}/>
                    <p className="mt-10 mb-4 text-lg">
                        Превью карточки (как будет смотреться)
                    </p>

                    <LongreadCard
                        img={bgImagePreview}
                        name={title || `Название ${getFormLabels(type).name}`}
                        to={`${CREATE_LONGREADS_ADMIN_PATH}?type=${type}`}
                        descr={description || `Описание ${getFormLabels(type).name}`}
                    />

                    <div
                        className="p-2 mt-16 inlne-block w-fit flex cursor-pointer text-sm justify-start items-center  rounded-md hover:bg-zinc-800 transition-all"
                        onClick={() => setInstructionOpened((prev) => !prev)}
                    >
                        <p className="">Инструкция</p>
                        <BsChevronDoubleDown className="ml-2" size={22}/>
                    </div>

                    {instructionOpened && (
                        <div className="text-zinc-400 max-w-[700px]">
                            <h3>
                                Редактор поддерживает основной синтаксиса markdown, а также
                                имеет дополнительные возможности
                            </h3>
                            <p>
                                Не поддерживаются таблицы, блоки кода и некоторые вещи которые
                                обычно накручиваются на markdown плагинами
                            </p>
                            <p className="text-white">Кастомные фитчи:</p>
                            <p>Чтобы быстро вставить картирку нажми ctrl+i</p>
                            <p>Можно вставить любой html, даже вместе со стилями</p>
                            <p>Чтобы центрировать текст напиши вот так:</p>
                            <p>{"<center>Текст</center>"}</p>
                            <p>Можно центрировать большой текст, например вот так:</p>
                            <p>{"# <center>Текст</center>"}</p>
                        </div>
                    )}

                    <p className="mt-4 text-xl">{`Контент ${getFormLabels(type).name}*`}</p>

                    <div className="flex mt-1">
						<textarea
                            placeholder={`Название ${getFormLabels(type).name} текст`}
                            ref={textareaRef}
                            value={inputed}
                            onChange={(e) => {
                                handleTextareaChange(e);
                            }}
                            onKeyDown={(e) => {
                                handleTextareaKeyPress(e);
                            }}
                            className="default-input min-w-[700px] w-[900px] min-h-[400px] "
                        />
                    </div>

                    {/* Big preview */}
                    <div className="mt-10 mb-10 max-w-[1000px] longread-typography longread-layout ">
                        <p className="text-zinc-600">{`Превью ${getFormLabels(type).name}`}</p>
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{inputed}</ReactMarkdown>
                    </div>
                    <hr className="mt-10 border-zinc-600 border-1 mb-10 w-96"/>

                    {type !== 0 &&
                        <>
                            <div>
                                <p>
                                    Добавить материалы <b>(по одному, полная ссылка)</b>
                                </p>
                                <input
                                    className="mt-1 default-input min-w-[300px]"
                                    value={material}
                                    onChange={(e) => setMaterial(e.target.value)}
                                    type="text"
                                />
                                <div
                                    className="mt-4 inlne-block w-40 text-center cursor-pointer border border-1 border-zinc-700 rounded-md hover:bg-zinc-500 transition-all"
                                    onClick={() => {
                                        if (material !== "") {
                                            setMaterials([...materials, material]);
                                            setMaterial("");
                                        }
                                    }}
                                >
                                    <p className="p-2">Добавить</p>
                                </div>
                            </div>


                            <div className="mt-4">
                                {materials.map((link) => {
                                    return (
                                        <div
                                            className="mt-2 max-w-[500px] w-[500px] flex justify-between items-center "
                                            key={link}
                                        >
                                            <a href={link} target={"_blank"}>
                                                {link}
                                            </a>
                                            <AiFillDelete
                                                onClick={() => {
                                                    setMaterials(
                                                        materials.filter((el) => {
                                                            return el != link;
                                                        })
                                                    );
                                                }}
                                                className="p-2 hover:text-red-500 cursor-pointer"
                                                size={40}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            <hr className="border-zinc-600 border-1 w-96 mb-8 mt-16"/>
                        </>
                    }

                    <div className="mb-16">
                        {!disabled ?
                            <div
                                className="inlne-block w-40 text-center cursor-pointer border border-1 border-zinc-700 rounded-md hover:bg-zinc-500 transition-all"
                                onClick={sendData}
                            >
                                <p className="p-2">ЗАГРУЗИТЬ</p>
                            </div> : <div>Для создания лонгрида, необходимо заполнить все обязательные поля</div>
                        }
                        {reqError && (
                            <h3 className="mt-4 text-red-600">
                                Ошибка! Пожалуйста, проверь поля
                            </h3>
                        )}
                    </div>
                </form>
            </section>
        </>
    );
}

export default CreateLongread;
