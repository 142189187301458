import "../../styles/componentStyles/button.css";
import {Link} from "react-router-dom";

const Button = ({ text, to, onClick, style }: any) => {
	if (!to) return (
		<div className="button" onClick={onClick} style={style}>
			<p>{text}</p>
		</div>
	)

	return (
		<Link to={`${to}`} className="button">
			<p>{text}</p>
		</Link>
	);
};

export default Button;
