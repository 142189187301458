import React from "react";
import Mrgn from "../../layout/Mrgn";
import "../../styles/componentStyles/etc/about.css";
import Footer from "../../layout/Footer";
import AboutCarousel from "./components/AboutCarousel";
import {Link} from "react-router-dom";
import "./styles.css"

function AboutUs() {
    return (
        <>
            <Mrgn/>
            <div className="about-main-container">
                <h1>
                    <span className="with">С</span> &nbsp;
                    <span className="logo">
						<img style={{width:150, margin:'0 -32px 0 -48px', zIndex:-1}} src="../../logo.png" alt=""/>
					</span>
                    {/* &nbsp; &nbsp; &nbsp; &nbsp; */}
                    <span className="non-in-non-human-black">(non)</span> вы выйдете
                    за рамки человеческого знания
                </h1>
                <div className="img1">
                    <img src="../../about/1.jpg" alt=""/>
                </div>
                <div className="who-are-we">
                    <h2>Кто мы?</h2>
                </div>

                <p style={{marginTop: "1em"}}>
                    Мы — образовательный центр, лекторий, в рамках которого читаются лекции, проводятся семинары и
                    организуются культурные вечера на различные темы.
                </p>
                <p>
                    Мы рассказываем о самых важных вещах, уделяя особое внимание фундаментальным знаниям, которые многие
                    сегодня забыли. Современный мир меняется очень быстро. В таких условиях знание является ключевым
                    фактором успешной и счастливой жизни.
                </p>
                <p>
                    Наши лекторы - <span className="white-bg">эксперты</span> в своей области, которые знают, как поделиться своими знаниями и опытом.
                    Вместе с ними мы рассказываем о сложных вещах доступным и увлекательным языком, связывая между собой
                    множество фактов и процессов, создавая целостную картину происходящего.
                </p>
                <AboutCarousel/>
                <p>
                    Наши гости становятся крепким сообществом единомышленников, которые
                    могут проводить время вместе, делиться друг с другом своим опытом и
                    знаниями.
                </p>
                <p>
                    Хотите узнать что сейчас происходит с наукой, искусством, обществом? А
                    может быть, вы пожелали бы провести вечер с пользой, в приятной
                    компании и красивом месте в центре города? - Тогда следите за анонсами
                    наших мероприятий в социальных сетях
                </p>
                <div className="socials-icons flex">
                    <a href="https://vk.com/nysacademy">
                        <img src="../socials/vk_icon.png" alt=""/>
                    </a>
                    <a href="https://www.youtube.com/channel/UCKwny_BSOEmQjNRIc7w7_JQ/">
                        <img src="../socials/yt_icon.png" alt=""/>
                    </a>
                    <a href="https://t.me/nysacademy">
                        <img src="../socials/tg_icon.png" alt=""/>
                    </a>
                </div>
                <div className="what-we-need">
                    <h2>
                        Наша{" "}
                        <span className="turn">
							<span className=" white-bg">миссия</span>
						</span>
                    </h2>
                </div>
                <div className="formula">
                    <p>
                        Учитель - очень ответственная позиция. Во всех существовавших аутентичных традициях роль учителя
                        была ключевой, можно даже сказать, абсолютной.
                        Не внешние регалии дают право учителю осуществлять свою работу. Безусловно, регалии придают вес
                        “личному мнению” эксперта, но цена даже самого весомого мнения никогда не приблизится к цене
                        истины.
                        Истина рождается не в головах конкретных людей, к ней приходят в ходе исследований, логических
                        построений и избавления от сбивающих ошибок.
                    </p>
                </div>
                <div className="waves">
                    <p>
                        Только тот, кто отказывается от своих личных амбиций и субъективных переживаний, только тот, кто
                        посвящает себя истине и становится ее проводником, насколько неудобной она ни была бы для него
                        самого, является настоящим учителем.
                        Передавать знания должны те, кто является учителем по призванию.
                    </p>
                </div>
                <div className="img-5" style={{marginTop: "-3em", marginBottom: "3em"}}>
                    <img src="../../about/5.png" alt=""/>
                    <p>
                        Наше призвание - передавать нашим гостям те знания, которые они не найдут в других местах.
                    </p>
                </div>
                <div className="what-we-need">
                    <h2>
                        Откуда мы берем знания?
                    </h2>
                </div>
                <p>
                    <b> Обширные академические связи. </b>
                    Будучи выходцами из Санкт-Петербургского государственного университета, мы - основная команда
                    проекта - сохранили связи в научных кругах России.
                    Мы также сотрудничаем с исследователями из Кембриджа и Манчестера.
                </p>
                <p>
                    <b>Наши собственные исследовательские группы проводят уникальные и новаторские исследования. </b>
                    К этой практике мы прибегаем в том случае, когда в какой-либо рассматриваемой нами теме остаются
                    белые пятна.
                </p>
                <p>
                    <b>Сбор знаний по всему миру. </b>
                    Если нам необходим сбор знаний, которые выходят за рамки открытого доступа, мы путешествуем в те
                    места, где их можно собрать.
                    К примеру, курсу “По ту сторону зеркала” предшествовала многолетняя работа - она началась задолго до
                    официального учреждения проекта. Использовался опыт всех членов нашей команды.
                    Кроме того, в поисках сакральных знаний мы бывали во многих закрытых местах, беседовали и состояли в
                    переписке с философами и учеными различных направлений.
                </p>

                <div className="img-6">
                    <img src="../../about/6.jpg" alt=""/>
                </div>

                <div className="what-we-need">
                    <h2>
                        Мы используем уникальную методику
                    </h2>
                </div>

                <p className="after-img-6">
                    В своей работе мы используем уникальную методику, которая основана на трех главных пунктах:
                </p>
                <p>
                    <b>Первое: </b>
                    Раскрытие фундаментальных основ любого рассматриваемого феномена. В ходе обучения у наших гостей
                    возникает комплексное представление о теме, которую они осваивают.
                </p>
                <p>
                    <b>Второе: </b>
                    Особое внимание к практической части. Там, где обучение подразумевает практику, мы не просто выдаем
                    сухие тестовые задания - мы делимся с нашими дорогими гостями упражнениями,
                    которые следует выполнять каждый день для того, чтобы новый навык закрепился буквально в вашем теле
                    и чтобы он ни при каких обстоятельствах не давал сбой.
                </p>
                <p>
                    <b>Третье: </b>
                    Введение в сообщество. Что толку от ваших навыков или даже от вашего внушительных размеров
                    портфолио, если вы не знаете, как на самом деле устроен рынок и не знаете нужных людей?
                    Знания и социальные связи - вот что является залогом успеха в современном мире, и наша цель -
                    предоставить эти ресурсы нашим гостям.
                </p>
                <p style={{width: "100%"}}>
                    О результатах такой работы можете почитать{" "}
                    <span>
						<Link to="https://vk.com/topic-217844762_49601223">здесь</Link>
					</span>
                    .
                </p>
                <p>
                    Познание - фундаментальный процесс, сопровождающий нас на протяжении всей жизни, но в современном
                    мире существует слишком много путей, ведущих к ложным знаниям. Именно поэтому очень важно выбирать
                    правильных учителей.
                    Только приход к истине может сделать человека по-настоящему счастливым.
                </p>
            </div>
            <Footer/>
        </>
    );
}

export default AboutUs;
