import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getCourseInfo} from "../api/coursesApi";

interface iArr {
	blockInfoBlockInfoId: string;
	blockInfoBlockInfoName: string;
	blockInfoIsWhatYouWillLearn: string;
	mat: any;
}

interface bArr {
	blockId: string;
	blockIsActive: string;
	blockIsDeployed: string;
	blockName: string;
	blockURLId: string;
	i: iArr[];
}

type CourseInfo = {
	m: {
		courseFormat: "Online" | "Offline";
		courseId: string;
		courseName: string;
		courseType: "Video" | "Audio";
		courseURLId: string;
		description: string;
		isActive: string;
		isAvailableToBuyCoursePart: string;
		isDeployed: string;
		p: {
			b: bArr[];
			courseCurrentPrice: string;
			courseDiscount: string;
			courseOldPrice: string;
			courseSubscriptionPrice: string;
		};
	};
};

const useCourseInfo = (URL: string) => {
	const [courseInfo, setCourseInfo] = useState<any>({});
	const params = useParams();
	useEffect(() => {
		const fetchCourseInfo = async () => {
			const response = await getCourseInfo(URL);
			setCourseInfo(response.data.m);
		};
		fetchCourseInfo();
	}, [URL]);
	return [courseInfo, setCourseInfo];
};

export default useCourseInfo;
